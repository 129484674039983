
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { makeDELETE, makeGET, makePATCH, makePOST } from "../../helpers/makeRequest";
import 'react-phone-number-input/style.css'
import Swal from "sweetalert2";
import TextField from '@mui/material/TextField'
import { InputAdornment, IconButton } from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import { MDBDataTableV5 } from 'mdbreact';
import { Visibility, VisibilityOff } from "@mui/icons-material";
import moment from 'moment';
import 'moment/locale/es';

export const UsuarioScreen = () => {
  const user = useSelector((state) => state.auth);
  useEffect(() => { }, []);
  const [datos, setDatos] = useState([]);

  const [adding, setAdding] = useState(false);

  const [userData, setUserData] = useState(JSON.parse(localStorage.getItem("userProps")))

  const [showPass, setShowPass] = useState(false);
  const [selectedClient, setSelectedClient] = useState((localStorage.getItem("selectedClient")))

  const [form, setForm] = useState({
    name: undefined,
    idrol: undefined,
    email: undefined,
    password: undefined,
    clients: undefined
  })

  const roles = [
    {
      label: "Admin",
      id: "admin"
    },
    {
      label: "Basic",
      id: "basic"
    }
  ]

  const [flag, setFlag] = useState(false)

  useEffect(() => {
    if (userData?.rol == "basic") {
      setFlag(true)
      setAdding(true)
      setForm({
        ...form,
        email: userData.email,
        name: userData.name
      })
    } else {
      getDatos()
    }
  }, [userData]);

  const handleEdit = (obj) => {
    setForm({
      ...obj,
      //idrol: roles.find(v => v.label == obj.rol).id 
    })
    setFlag(true)
    setAdding(true)
  };

  const [clients, setClients] = useState(userData?.clients)

  const getDatos = async (val = undefined) => {
    console.log(selectedClient)
    if (userData?.rol == 'admin') {
      makeGET("user/get/" + selectedClient).then(({ data }) => {
        console.log(data)
        data.forEach(element => {
          element.estado = element.status == 1 ? "Activo" : "Inactivo"
          element._id = String(element._id).replace(/"/g, '')
          element.creado = moment(new Date(element.created.$date)).format('MMM/DD/YYYY HH:mm:ss').toUpperCase().replace('.', '')
          element.buttons =
            <div className="text-center">
              <button key="update" onClick={e => { handleEdit(element) }} className="btn btn-sm btn-primary mr-1">
                <i className="fas fa-edit"></i>
              </button>
              <button key="delete" onClick={e => { deleteDatos(element) }} className="btn btn-sm btn-danger">
                <i className="fas fa-trash"></i>
              </button>
            </div>
        });
        const data_conf = {
          columns: [
            {
              label: 'Name',
              field: 'name'
            },
            {
              label: 'Email',
              field: 'email',
            },
            {
              label: 'Rol',
              field: 'rol',
            },
            {
              label: 'Status',
              field: 'estado',
            },
            {
              label: 'Created',
              field: 'creado',
            },
            {
              label: '',
              field: 'buttons'
            }
          ],
          rows: data
        }
        setDatos(data_conf)
      }).catch((e) => {
        //console.log(JSON.parse(JSON.stringify(e)).status)
      })
    } else {
      let currentData = JSON.parse(localStorage.getItem("userProps"))
      currentData.name = val.name
      currentData.email = val.email
      localStorage.setItem("userProps", JSON.stringify(currentData))
      //console.log({...currentData})
      //setUserData({...currentData})
    }
  };
  const createPayload = (forms, clean = false) => {
    let payload = {
      ...forms,
      iduser: userData._id,
      rol: forms.idrol
    }
    if (!clean) {
      payload.status = 1
    }
    return payload
  }
  const createDatos = async () => {
    const payload = createPayload(form)
    makePOST("auth/signup", payload).then((v) => {
      getDatos()
      resetForm()
      setFlag(false)
      setShowPass(false)
      setAdding(false)
      Swal.fire("Éxito!", "Registro agregado", "success")
    }).catch((c) => {
      Swal.fire("Error", "Ocurrió un error", "error")
    })
  }

  const resetForm = () => {
    Object.keys(form).map((key) => {
      form[key] = undefined
      if (key == "coordinates") {
        form[key] = [undefined, undefined]
      }
    })
  }

  const updateDatos = async (val) => {
    let payload = createPayload(form, true)
    payload = {
      iduser: payload._id,
      email: payload.email, 
      name: payload.name
    }
    
    makePATCH("user/edit", payload).then((v) => {
      getDatos(createPayload(form, true))
      if (userData?.rol == 'admin') {
        setFlag(false)
        setShowPass(false)
        setAdding(false)
        resetForm()
      }
      Swal.fire("Éxito!", "Registro actualizado", "success")
    }).catch((c) => {
      //console.log(c)
      Swal.fire("Error", "Ocurrió un error", "error")
    })
  };

  const deleteDatos = async (val) => {
    Swal.fire("Atención", "¿Estás seguro que quieres eliminar los registros seleccionados? " + val.name, "warning")
      .then((result) => {
        if (result.isConfirmed) {
          makeDELETE("user/delete", { iduser: String(val._id).replace(/[^a-zA-Z0-9]/g, '') }).then((v) => {
            getDatos()
            Swal.fire("Éxito!", "Borrado completado", "success")
          }).catch((c) => {
            Swal.fire("Error", "Ocurrió un error", "error")
          })
        }
      })
  };

  const toggleAdd = () => {
    setAdding(true);
    setFlag(false)
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  const undReturn = (val) => {
    if (val == undefined) {
      return ""
    }
    return val
  }

  const undReturnArr = (val) => {
    if (val == undefined) {
      return []
    }
    return val
  }

  const toggleClose = () => {
    setAdding(false);
  };

  const checkForm = () => {
    if (form.name?.length == 0 || (flag && form.rol?.length == 0) || (flag && form.password?.length == 0)
      || !validateEmail(form.email)) {
      return true
    }
    return false
  }

  return (
    <>
      <div className="card m-3">
        <h5 className="card-header d-flex justify-content-between align-items-center">
          {userData?.rol == 'admin' ? "Usuarios" : "Usuario"}
          {userData?.rol == 'admin' &&
            <>
              {adding ? (
                <button
                  type="button"
                  className="btn btn-sm btn-danger"
                  onClick={() => {
                    toggleClose()
                    if (flag) {
                      setFlag(false)
                      userData?.rol == 'admin' && resetForm()
                    }
                  }}
                >
                  <i className="fas fa-times"></i>
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-sm btn-success"
                  onClick={() => toggleAdd()}
                >
                  <i className="fas fa-plus"></i>
                </button>
              )}
            </>
          }
        </h5>
        {adding && (
          <div className="card-body row">
            <div className={"col-12 col-lg-6 text-center" + (!(form.name?.length == 0 && form.name != undefined) && ' mb-3')}>
              <TextField
                type="text"
                fullWidth
                onChange={(e) => {
                  setForm({ ...form, name: e.target.value })
                }}
                value={undReturn(form.name)}
                autoComplete="off"
                placeholder={localStorage.getItem("selectedClient") + "..."}
                required
                size="small"
                sx={{ input: { backgroundColor: "whitesmoke", borderRadius: '2px' } }}
                variant="outlined"
                label="Name"
                error={(form.name?.length == 0 && form.name != undefined)}
                helperText={(form.name?.length == 0 && form.name != undefined) && "El campo name es obligatorio"}
                style={{ marginBottom: (form.name?.length == 0 && form.name != undefined) && '5px' }}
              />
            </div>
            {!flag &&
              <>
                <div className={"col-12 col-lg-6 text-center" + (!(form.clients?.length == 0 && form.clients != undefined) && ' mb-3')}>
                  <TextField
                    id="outlined-select-currency"
                    select
                    SelectProps={{ multiple: true }}
                    label="Clientes"
                    autoComplete="off"
                    placeholder="Clientes"
                    fullWidth
                    sx={{ boxShadow: { backgroundColor: "whitesmoke", borderRadius: '2px' } }}
                    value={undReturnArr(form.clients)}
                    onChange={(e) => {
                      setForm({ ...form, clients: e.target.value })
                    }}
                    error={(form.clients?.length == 0 && form.clients != undefined)}
                    helperText={(form.clients?.length == 0 && form.clients != undefined) && "El cliente es requerido"}
                    size="small"
                    variant="outlined"
                    style={{ marginBottom: (form.clients?.length == 0 && form.clients != undefined) && '5px' }}
                  >
                    {clients.map((r) => (
                      <MenuItem key={r} value={r}>
                        {String(r).toUpperCase()}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div className={"col-12 col-lg-6 text-center" + (!(form.idrol?.length == 0 && form.idrol != undefined) && ' mb-3')}>
                  <TextField
                    id="outlined-select-currency"
                    select
                    label="Rol"
                    autoComplete="off"
                    placeholder="Rol"
                    fullWidth
                    sx={{ boxShadow: { backgroundColor: "whitesmoke", borderRadius: '2px' } }}
                    value={undReturn(form.idrol)}
                    onChange={(e) => {
                      setForm({ ...form, idrol: e.target.value })
                    }}
                    error={(form.idrol?.length == 0 && form.idrol != undefined)}
                    helperText={(form.idrol?.length == 0 && form.idrol != undefined) && "El rol es requerido"}
                    size="small"
                    variant="outlined"
                    style={{ marginBottom: (form.idrol?.length == 0 && form.idrol != undefined) && '5px' }}
                  >
                    {roles.map((r) => (
                      <MenuItem key={r.id} value={r.id}>
                        {r.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </>
            }
            <div className={"col-12 col-lg-6 text-center" + (!(!validateEmail(form.email) && form.email != undefined) && ' mb-3')}>
              <TextField
                type="email"
                fullWidth
                onChange={(e) => {
                  setForm({ ...form, email: e.target.value })
                }}
                value={undReturn(form.email)}
                autoComplete="off"
                placeholder={localStorage.getItem("selectedClient") + "@..."}
                required
                error={(!validateEmail(form.email) && form.email != undefined)}
                helperText={(!validateEmail(form.email) && form.email != undefined) && "Formato de email no válido"}
                size="small"
                variant="outlined"
                label="Email"
                sx={{ input: { backgroundColor: "whitesmoke", borderRadius: '2px' } }}
                style={{ marginBottom: (!validateEmail(form.email) && form.email != undefined) && '5px' }}
              />
            </div>
            {!flag &&
              <div className={"col-12 col-lg-6 text-center" + (!(form.password != undefined) && ' mb-3')}>
                <TextField
                  type={showPass ? "text" : "password"}
                  fullWidth
                  onChange={(e) => {
                    setForm({ ...form, password: e.target.value })
                  }}
                  value={undReturn(form.password)}
                  autoComplete="off"
                  required
                  error={(form.password?.length == 0 && form.password != undefined)}
                  helperText={(form.password?.length == 0 && form.password != undefined) && "El password es requerido"}
                  size="small"
                  variant="outlined"
                  label="Password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end"
                        sx={{ input: { backgroundColor: "whitesmoke", borderRadius: '2px' } }}
                        style={{ backgroundColor: "whitesmoke", borderRadius: '2px' }}>
                        <IconButton
                          style={{ backgroundColor: "whitesmoke", borderRadius: '2px' }}
                          onClick={(e) => { setShowPass(!showPass) }}
                          onMouseDown={(e) => { setShowPass(!showPass) }}
                          edge="end"
                        >
                          {showPass ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  sx={{ input: { backgroundColor: "whitesmoke", borderRadius: '2px' } }}
                  style={{ marginBottom: (form.password?.length == 0 && form.password != undefined) && '5px', backgroundColor: "whitesmoke", borderRadius: '2px' }}
                />
              </div>
            }
            <div className="col-12 text-center mt-2">
              <button
                type="button"
                className={"btn btn-large " + (flag ? "btn-success" : "btn-primary")}
                onClick={flag ? updateDatos : createDatos}
                disabled={checkForm()}
                style={{
                  paddingLeft: "2.5rem",
                  paddingRight: "2.5rem",
                  fontSize: "18px",
                }}
              >
                {flag ? "Modificar" : "Añadir"}
              </button>
            </div>
          </div>
        )}
        {userData?.rol == 'admin' &&
          <div className="card-body row">
            <div className="col-12">
              <MDBDataTableV5
                striped
                responsiveSm
                responsive
                responsiveMd
                responsiveLg
                responsiveXl
                hover
                entriesLabel="Por página"
                pagingTop
                infoLabel={["Página", " - ", "de", "registros"]}
                paginationLabel={["Anterior", "Siguiente"]}
                searchLabel="Buscar..."
                className="mdb"
                searchTop
                searchBottom={false}
                noBottomColumns
                data={datos}
              />
            </div>
          </div>}
      </div>
    </>
  );
};
