
import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { makeGET, makePATCH, makePOST } from "../../helpers/makeRequest";
import 'react-phone-number-input/style.css'
import GoogleMapReact from 'google-map-react';
import { BlockPicker } from "react-color";
import { conf } from "../../config/config";
import imageCompression from 'browser-image-compression';
const baseURL = conf.apiURL;

const PinPoint = ({ src }) => <div><img style={{ maxWidth: '40px', maxHeigth: '40px' }} src={src} /></div>;

export const FrameScreen = () => {
    const user = useSelector((state) => state.auth);
    const [datos, setDatos] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);
    const [widgetData, setWidgetData] = useState({})
    const [selectedImg, setSelectedImg] = useState(null)
    const [imgSrc, setImgSrc] = useState("https://buscadores.adn.com.mx/assets/pmsc/img/pinpoint.png")
    const [changed, setChanged] = useState(false)
    const [displayColor, setDisplayColor] = useState(false)
    const [color, setColor] = useState("#98012e")
    const [copySuccess, setCopySuccess] = useState(false)
    const [selectedClient, setSelectedClient] = useState((localStorage.getItem("selectedClient")))
    const inputFileRef = useRef(null);

    const defaultProps = {
        center: {
            lat: 19.42053,
            lng: -99.09412
        },
        zoom: 10
    };

    useEffect(() => {
        getWidgetDetails()
    }, []);

    const getWidgetDetails = () => {
        makeGET("widget/get/" + localStorage.getItem("selectedClient")).then(({ data }) => {
            if (data) {
                setColor(data.color)
                setImgSrc(baseURL + data.pinpoint)
                setWidgetData(data)
            }
        }).catch((e) => {
            //console.log(JSON.parse(JSON.stringify(e)))
        })
    }

    const saveWidgetDetails = () => {
        const fd = new FormData()
        fd.append("client", localStorage.getItem("selectedClient"))
        fd.append("color", color)
        fd.append("pinpoint", selectedImg)
        if (widgetData) {
            makePATCH("widget/update", fd, true).then(({ data }) => {
                getWidgetDetails()
                setChanged(false)
            }).catch((e) => {
                console.log(JSON.parse(JSON.stringify(e)))
            })
        } else {
            makePOST("widget/save", fd, true).then(({ data }) => {
                getWidgetDetails()
                setChanged(false)
            }).catch((e) => {
                //console.log(JSON.parse(JSON.stringify(e)))
            })
        }
    }

    const handleChange = (color) => {
        setColor(color.hex)
        setChanged(true)
        setDisplayColor(!displayColor)
    };

    const onFileChange = async (e) => {
        const options = {
            maxSizeMB: 0.3,
            maxWidthOrHeight: 40,
            useWebWorker: true,
            initialQuality: 0.8
        }
        var selectedFile = await imageCompression(e.target.files[0], options);
        var reader = new FileReader();

        /*var imgtag = document.getElementById("myimage");
        imgtag.title = selectedFile.name;*/

        reader.onload = function (event) {
            setImgSrc(event.target.result)
            setChanged(true)
            setSelectedImg(selectedFile)
        };

        reader.readAsDataURL(selectedFile);
    }

    const onDivClick = () => {
        inputFileRef.current.click();
    }

    return (
        <>
            <div className="card m-3">
                <h5 className="card-header d-flex justify-content-between align-items-center">
                    Iframe de mapa
                </h5>

                <div className="card-body row align-bottom">
                    <div className="card bg-light p-1 mb-3" style={{ width: "100%" }}>
                        <h5 className="card-title text-center">Personalización del widget</h5>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-3 text-right">
                                    <label style={{ fontFamily: "Exo", fontSize: "1em", paddingTop: "5px", paddingLeft: "15px" }}>Color</label>
                                </div>
                                <div className="col-3 text-left">
                                    <div style={{
                                        padding: "5px",
                                        background: "rgb(255, 255, 255)",
                                        borderRadius: "1px",
                                        boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 0px 1px",
                                        display: "inline-block",
                                        cursor: "pointer"
                                    }}
                                        onClick={() => { setDisplayColor(!displayColor) }}>
                                        <div style={{
                                            width: "36px",
                                            height: "14px",
                                            borderRadius: "2px",
                                            background: color
                                        }}></div>
                                    </div>
                                    {displayColor &&
                                        <div style={{ position: "absolute", zIndex: "2" }}>
                                            <div style={{
                                                position: "fixed", top: '0px',
                                                right: '0px',
                                                bottom: '0px',
                                                left: '0px',
                                            }} onClick={() => { setDisplayColor(false) }} />
                                            <BlockPicker
                                                color={color}
                                                onChange={handleChange}
                                                triangle={"hide"}
                                                colors={[
                                                    '#98012e',
                                                    '#D9E3F0', '#F47373', '#697689',
                                                    '#37D67A', '#2CCCE4', '#555555',
                                                    '#dce775', '#ff8a65', '#ba68c8',
                                                ]}
                                            />
                                        </div>
                                    }
                                </div>
                                <div className="col-3">
                                    <label style={{ fontFamily: "Exo", fontSize: "1em", paddingTop: "5px", paddingLeft: "15px" }}>PinPoint</label>
                                </div>
                                <div className="col-3" style={{ cursor: "pointer" }} onClick={onDivClick}>
                                    <input
                                        type="file"
                                        ref={inputFileRef}
                                        style={{ display: "none" }}
                                        onChange={onFileChange}
                                        accept="image/*"
                                    />
                                    <PinPoint src={imgSrc} />
                                </div>
                                {changed &&
                                    <div className="col-12 text-center m-0 p-0">
                                        <button
                                            type="button"
                                            className="btn btn-sm btn-success"
                                            onClick={() => saveWidgetDetails()}
                                        >
                                            Guardar <i className="fas fa-save"></i>
                                        </button>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="input-group mb-3 text-center">
                        <input type="text" className="form-control"
                            value={'<iframe width="100%" height="550px" src="' + conf.apiURL + 'widget/' + selectedClient + '" frameBorder="0"></iframe>'}
                            aria-describedby="basic-addon2" disabled={true} />
                        <div className="input-group-append" style={{ cursor: "pointer" }} onClick={(e) => {
                            e.preventDefault()
                            navigator.clipboard.writeText('<iframe width="100%" height="550px" src="' + conf.apiURL + 'widget/' + selectedClient + '" frameBorder="0"></iframe>')
                            setCopySuccess(true)
                            setTimeout(() => {
                                setCopySuccess(false)
                            }, 1500);
                        }}>
                            <span className={"input-group-text " + (copySuccess ? "bg-success text-white" : "")}
                                id="basic-addon2">
                                {copySuccess ? <i className="fas fa-check-circle"></i> : <i className="fas fa-copy"></i>}
                            </span>
                        </div>
                    </div>


                    <div className="card p-1 mb-3" style={{ width: "100%" }}>
                        <h5 className="card-title text-center">Preview</h5>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12">
                                    <div className="row">
                                        <label htmlFor="mySelect" style={{ fontFamily: "Exo", fontSize: "1em", paddingTop: "5px", paddingLeft: "15px" }}>Filtrar por Estado</label>
                                        <select id="mySelect" style={{
                                            fontFamily: "Exo",
                                            width: "200px",
                                            fontSize: "1em",
                                            height: "38px",
                                            padding: "6px 10px",
                                            backgroundColor: "#fff",
                                            border: "1px solid #D1D1D1",
                                            borderRadius: "4px",
                                            boxShadow: "none",
                                            boxSizing: "border-box",
                                            marginLeft: "10px",
                                        }}>
                                            <option>{"Selecciona"}</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6 pmsc-left" style={{ padding: "0", height: '400px', margin: "0" }}>
                                    <div className="pmsc-filter">
                                        <input className="pmsc-f-i" placeholder="Buscar franquicias..." type="text" style={{ borderBottom: "solid 1px " + color }} />
                                        <button className="pmsc-f-b" style={{ backgroundColor: color }} value="submit" type="submit">
                                            <i className="material-icons">search</i>
                                        </button>
                                    </div>
                                    <div style={{ height: "100%" }}>
                                        <ul style={{ textAlign: "left" }}>
                                            {
                                                datos
                                                    .map((item, idx) => (
                                                        <li style={{ width: "100%" }} key={idx}>
                                                            <div className="pmsc-loc" style={{ color: color }}><i className="material-icons">location_on</i></div>
                                                            <div className="pmsc-title">Sucursal {item}</div>
                                                            {idx % 2 == 0 &&
                                                                <a href={"https://www.google.com"}
                                                                    className="online" style={{ color: color }} target="_blank">
                                                                    ¡Servicios Online!
                                                                </a>
                                                            }
                                                            <div className="pmsc-address">Dirección {item}</div>
                                                            <div className="pmsc-phone">
                                                                <a style={{ backgroundColor: color }} href={"tel:22645161646"}>
                                                                    <i className="material-icons">phone</i> {"22645161646".replace(/(\d{2})(\d{4})(\d{4})/, '$1 $2 $3')}
                                                                </a>
                                                                {idx % 2 != 0 &&
                                                                    <a style={{ backgroundColor: color }} href={"tel:22645161646"}>
                                                                        <i className="material-icons">phone</i> {"22645161646".replace(/(\d{2})(\d{4})(\d{4})/, '$1 $2 $3')}
                                                                    </a>
                                                                }
                                                                <a style={{ backgroundColor: color }} target="_blank" href={"https://www.google.com"}>
                                                                    <i className="material-icons">public</i>  Sitio Web
                                                                </a>
                                                                <a style={{ backgroundColor: color }} target="_blank"
                                                                    href={"https://www.google.com"}>
                                                                    <i className="material-icons">business</i>  Cotiza
                                                                </a>
                                                            </div>
                                                        </li>
                                                    ))}
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6" style={{ height: '400px', width: '100%' }}>
                                    <GoogleMapReact
                                        bootstrapURLKeys={{ key: "AIzaSyAuL7Fex_oUVH77ZC9h99ZeeCsSfMYLrMI" }}
                                        defaultCenter={defaultProps.center}
                                        defaultZoom={defaultProps.zoom}
                                    >
                                        <PinPoint
                                            lat={19.42053}
                                            lng={-99.09412}
                                            src={imgSrc}
                                        />
                                    </GoogleMapReact>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </>
    );
};
