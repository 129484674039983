export const sucursales = [
    {
        "_id": {
            "$oid": "65567b7d3cadfb091b0c935a"
        },
        "address": "Prolongación Ignacio Zaragoza 212, Col. Fatima",
        "name": "Vitrocar Aguascalientes Fatima",
        "state": "Aguascalientes",
        "phone": "8001118487",
        "phone2": "4499932718",
        "lat": "21.91280809893124",
        "lng": -102.30341308999019,
        "sitio": "https://maps.google.com/maps?cid=10874382732658156615",
        "mx": "VITRO0",
        "client": "vitrocar",
        "cotiza": "https://maps.google.com/maps?cid=10874382732658156615",
        "deleted_at": null,
        "coordinates": [
            -102.30341308999019,
            21.91280809893124
        ],
        "email": "vitrocar@vitro.com"
    },
    {
        "_id": {
            "$oid": "65567e723cadfb091b0c9396"
        },
        "address": "Prolongación Morelos, C. Ejido Manzana 216 Lote 5, Cabo San Lucas, B.C.S.",
        "name": "Vitrocar Los Cabos",
        "state": "Baja California Sur",
        "phone": "6241051772",
        "phone2": "6241051979",
        "lat": "22.89657108551969",
        "lng": -109.91960089140127,
        "sitio": "https://maps.google.com/maps?cid=2093371957025260908",
        "mx": "VITRO1",
        "client": "vitrocar",
        "cotiza": "https://maps.google.com/maps?cid=2093371957025260908",
        "deleted_at": null,
        "coordinates": [
            -109.91960089140127,
            22.89657108551969
        ],
        "email": "VITRO1@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567e723cadfb091b0c9397"
        },
        "address": "Blvrd Anáhuac 863 Cruce con Lazaro Cárdenas, Fracc. Ampliación Jardines del Lago, Mexicali, B.C.",
        "name": "Vitrocar Mexicali",
        "state": "Baja California",
        "phone": "8001118487",
        "phone2": "6868387729",
        "lat": "32.62447651320791",
        "lng": -115.47870536441808,
        "sitio": "https://maps.google.com/maps?cid=4229554109623974692",
        "mx": "VITRO2",
        "client": "vitrocar",
        "cotiza": "https://maps.google.com/maps?cid=4229554109623974692",
        "deleted_at": null,
        "coordinates": [
            -115.47870536441808,
            32.62447651320791
        ],
        "email": "VITRO2@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567e723cadfb091b0c9398"
        },
        "address": "Leona Vicario 1516 B, Zona Urbana Rio Tijuana, Tijuana, B.C.",
        "name": "Vitrocar Tijuana",
        "state": "Baja California",
        "phone": "8001118487",
        "phone2": "6646847518",
        "lat": "32.52963357559639",
        "lng": -117.02613701795366,
        "sitio": "https://maps.google.com/maps?cid=9066367937246393666",
        "mx": "VITRO3",
        "client": "vitrocar",
        "cotiza": "https://maps.google.com/maps?cid=9066367937246393666",
        "deleted_at": null,
        "coordinates": [
            -117.02613701795366,
            32.52963357559639
        ],
        "email": "VITRO3@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567e723cadfb091b0c9399"
        },
        "address": "Quinta Norte Poniente 1523, Moctezuma, Tuxtla Gutiérrez, Chis.",
        "name": "Vitrocar Tuxtla Quinta Norte",
        "state": "Chiapas",
        "phone": "8001118487",
        "phone2": "9611251852",
        "lat": "16.76066575336413",
        "lng": -93.12923033558194,
        "sitio": "https://maps.google.com/maps?cid=9206639468838249850",
        "mx": "VITRO4",
        "client": "vitrocar",
        "cotiza": "https://maps.google.com/maps?cid=9206639468838249850",
        "deleted_at": null,
        "coordinates": [
            -93.12923033558194,
            16.76066575336413
        ],
        "email": "VITRO4@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567e723cadfb091b0c939a"
        },
        "address": "Avenida Universidad 2304, Col.Altavista, Chihuahua, Chih.",
        "name": "Vitrocar Chihuahua Universidad",
        "state": "Chihuahua",
        "phone": "8001118487",
        "phone2": "6144148140",
        "lat": "28.649490060147055",
        "lng": -106.08353244907353,
        "sitio": "https://maps.google.com/maps?cid=13458085836640926662",
        "mx": "VITRO5",
        "client": "vitrocar",
        "cotiza": "https://maps.google.com/maps?cid=13458085836640926662",
        "deleted_at": null,
        "coordinates": [
            -106.08353244907353,
            28.649490060147055
        ],
        "email": "VITRO5@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567e723cadfb091b0c939b"
        },
        "address": "Blvd. Jesús Valdez Sánchez 889, República Oriente, Saltillo, Coah.",
        "name": "Vitrocar Saltillo",
        "state": "Coahuila",
        "phone": "8001118487",
        "phone2": "8444308935",
        "lat": "25.43673249744514",
        "lng": -100.98492816256514,
        "sitio": "https://maps.google.com/maps?cid=12125664550873390866",
        "mx": "VITRO6",
        "client": "vitrocar",
        "cotiza": "https://maps.google.com/maps?cid=12125664550873390866",
        "deleted_at": null,
        "coordinates": [
            -100.98492816256514,
            25.43673249744514
        ],
        "email": "VITRO6@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567e723cadfb091b0c939c"
        },
        "address": "Calz Matías Román Ríos 488, Centro Oriente, Torreón, Coah.",
        "name": "Vitrocar Torreón",
        "state": "Coahuila",
        "phone": "8001118487",
        "phone2": "8717137337",
        "lat": "25.536646653322705",
        "lng": -103.42923646441805,
        "sitio": "https://maps.google.com/maps?cid=8351130689530924853",
        "mx": "VITRO7",
        "client": "vitrocar",
        "cotiza": "https://maps.google.com/maps?cid=8351130689530924853",
        "deleted_at": null,
        "coordinates": [
            -103.42923646441805,
            25.536646653322705
        ],
        "email": "VITRO7@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567e723cadfb091b0c939d"
        },
        "address": "Av. Francia 301, La Moderna, León, Gto.",
        "name": "Vitrocar León",
        "state": "Guanajuato",
        "phone": "8001118487",
        "phone2": "4777178213",
        "lat": "21.13507615722038",
        "lng": -101.69124674907353,
        "sitio": "https://maps.google.com/maps?cid=7962646368534332537",
        "mx": "VITRO8",
        "client": "vitrocar",
        "cotiza": "https://maps.google.com/maps?cid=7962646368534332537",
        "deleted_at": null,
        "coordinates": [
            -101.69124674907353,
            21.13507615722038
        ],
        "email": "VITRO8@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567e723cadfb091b0c939e"
        },
        "address": "Vasco Núñez de Balboa 3, Hornos, Acapulco de Juárez, Gro.",
        "name": "Vitrocar Acapulco",
        "state": "Guerrero",
        "phone": "8001118487",
        "phone2": "7444861773",
        "lat": "16.856413221670397",
        "lng": -99.8980066932542,
        "sitio": "https://maps.google.com/maps?cid=11553175064255851670",
        "mx": "VITRO9",
        "client": "vitrocar",
        "cotiza": "https://maps.google.com/maps?cid=11553175064255851670",
        "deleted_at": null,
        "coordinates": [
            -99.8980066932542,
            16.856413221670397
        ],
        "email": "VITRO9@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567e723cadfb091b0c939f"
        },
        "address": "Las Américas 592, Col.Lázaro Cárdenas, Puerto Vallarta, Jal.",
        "name": "Vitrocar Puerto Vallarta",
        "state": "Jalisco",
        "phone": "3222235358",
        "phone2": "3222235734",
        "lat": "20.624938018263865",
        "lng": -105.22414656256514,
        "sitio": "https://maps.google.com/maps?cid=740873242089793520",
        "mx": "VITRO10",
        "client": "vitrocar",
        "cotiza": "https://maps.google.com/maps?cid=740873242089793520",
        "deleted_at": null,
        "coordinates": [
            -105.22414656256514,
            20.624938018263865
        ],
        "email": "VITRO10@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567e723cadfb091b0c93a0"
        },
        "address": "Av. Adolfo López Mateos Sur 5490, Arboledas, Zapopan, Jal.",
        "name": "Vitrocar Zapopan",
        "state": "Jalisco",
        "phone": "8001118487",
        "phone2": "3336324607",
        "lat": "20.627134838831598",
        "lng": -103.41923490859874,
        "sitio": "https://maps.google.com/maps?cid=1372491606750286764",
        "mx": "VITRO11",
        "client": "vitrocar",
        "cotiza": "https://maps.google.com/maps?cid=1372491606750286764",
        "deleted_at": null,
        "coordinates": [
            -103.41923490859874,
            20.627134838831598
        ],
        "email": "VITRO11@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567e723cadfb091b0c93a1"
        },
        "address": "José María Morelos 122, Las Palmas, Cuernavaca, Mor.",
        "name": "Vitrocar Cuernavaca",
        "state": "Morelos",
        "phone": "8001118487",
        "phone2": "7773105977",
        "lat": "18.908799297336742",
        "lng": -99.23295010674579,
        "sitio": "https://maps.google.com/maps?cid=12711650196303746544",
        "mx": "VITRO12",
        "client": "vitrocar",
        "cotiza": "https://maps.google.com/maps?cid=12711650196303746544",
        "deleted_at": null,
        "coordinates": [
            -99.23295010674579,
            18.908799297336742
        ],
        "email": "VITRO12@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567e723cadfb091b0c93a2"
        },
        "address": "Av. Francisco I. Madero 2950, Mitras Sur, Monterrey, N.L.",
        "name": "Vitrocar Monterrey Mitras",
        "state": "Nuevo León",
        "phone": "8001118487",
        "phone2": "8183476029",
        "lat": "25.687317339452107",
        "lng": -100.34446586441807,
        "sitio": "https://maps.google.com/maps?cid=7164007971453312126",
        "mx": "VITRO13",
        "client": "vitrocar",
        "cotiza": "https://maps.google.com/maps?cid=7164007971453312126",
        "deleted_at": null,
        "coordinates": [
            -100.34446586441807,
            25.687317339452107
        ],
        "email": "VITRO13@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567e723cadfb091b0c93a3"
        },
        "address": "Av. Francisco I. Madero 2923, Col.Acero, Monterrey, N.L.",
        "name": "Vitrocar Monterrey Y Griega",
        "state": "Nuevo León",
        "phone": "8001118487",
        "phone2": "8183546890",
        "lat": "25.682792225471864",
        "lng": -100.28452671103875,
        "sitio": "https://maps.google.com/maps?cid=8639031802738590867",
        "mx": "VITRO14",
        "client": "vitrocar",
        "cotiza": "https://maps.google.com/maps?cid=8639031802738590867",
        "deleted_at": null,
        "coordinates": [
            -100.28452671103875,
            25.682792225471864
        ],
        "email": "VITRO14@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567e723cadfb091b0c93a4"
        },
        "address": "Av. Manuel L. Barragán 221, Villa Universidad, San Nicolás de los Garza, N.L.",
        "name": "Vitrocar San Nicolás de los Garza",
        "state": "Nuevo León",
        "phone": "8001118487",
        "phone2": "8183325180",
        "lat": "25.74633230391116",
        "lng": -100.31077295092646,
        "sitio": "https://maps.google.com/maps?cid=291565686937879506",
        "mx": "VITRO15",
        "client": "vitrocar",
        "cotiza": "https://maps.google.com/maps?cid=291565686937879506",
        "deleted_at": null,
        "coordinates": [
            -100.31077295092646,
            25.74633230391116
        ],
        "email": "VITRO15@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567e723cadfb091b0c93a5"
        },
        "address": "Blvd. Eduardo Vasconcelos 207, Col.Centro, Oaxaca de Juárez, Oax.",
        "name": "Vitrocar Oaxaca",
        "state": "Oaxaca",
        "phone": "8001118487",
        "phone2": "9515155196",
        "lat": "17.062485602172405",
        "lng": -96.71429693558194,
        "sitio": "https://maps.google.com/maps?cid=4157570695643157446",
        "mx": "VITRO16",
        "client": "vitrocar",
        "cotiza": "https://maps.google.com/maps?cid=4157570695643157446",
        "deleted_at": null,
        "coordinates": [
            -96.71429693558194,
            17.062485602172405
        ],
        "email": "VITRO16@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567e723cadfb091b0c93a6"
        },
        "address": "Av. 2 Pte 3105, Amor, 72140 Puebla, Pue.",
        "name": "Vitrocar Puebla Matriz",
        "state": "Puebla",
        "phone": "8001118487",
        "phone2": "2222303503",
        "lat": "19.057696275142174",
        "lng": -98.22066882209032,
        "sitio": "https://maps.google.com/maps?cid=533984925250515468",
        "mx": "VITRO17",
        "client": "vitrocar",
        "cotiza": "https://maps.google.com/maps?cid=533984925250515468",
        "deleted_at": null,
        "coordinates": [
            -98.22066882209032,
            19.057696275142174
        ],
        "email": "VITRO17@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567e723cadfb091b0c93a7"
        },
        "address": "Av. Prol. Muñoz 380 A, Col del Río, San Luis, S.L.P.",
        "name": "Vitrocar San Luis Potosí",
        "state": "San Luis Potosí",
        "phone": "8001118487",
        "phone2": "4448138098",
        "lat": "22.16435222081544",
        "lng": -101.00061004907353,
        "sitio": "https://maps.google.com/maps?cid=14418452059462501345",
        "mx": "VITRO18",
        "client": "vitrocar",
        "cotiza": "https://maps.google.com/maps?cid=14418452059462501345",
        "deleted_at": null,
        "coordinates": [
            -101.00061004907353,
            22.16435222081544
        ],
        "email": "VITRO18@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567e723cadfb091b0c93a8"
        },
        "address": "Río Sonora Poniente 845, Industrial Bravo, Culiacán Rosales, Sin.",
        "name": "Vitrocar Culiacán",
        "state": "Sinaloa",
        "phone": "6677143400",
        "phone2": "6677143403",
        "lat": "24.79515387423497",
        "lng": -107.40128152209031,
        "sitio": "https://maps.google.com/maps?cid=17595243476033863162",
        "mx": "VITRO19",
        "client": "vitrocar",
        "cotiza": "https://maps.google.com/maps?cid=17595243476033863162",
        "deleted_at": null,
        "coordinates": [
            -107.40128152209031,
            24.79515387423497
        ],
        "email": "VITRO19@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567eca3cadfb091b0c93a9"
        },
        "address": "Av. Gabriel Leyva 103, Loma de Juárez, Col. Juárez, Mazatlán, Sin.",
        "name": "Vitrocar Mazatlán",
        "state": "Sinaloa",
        "phone": "8001118487",
        "phone2": "6691120312",
        "lat": "23.228152902009356",
        "lng": -106.39878907976258,
        "sitio": "https://maps.google.com/maps?cid=5767179233935237502",
        "mx": "VITRO20",
        "client": "vitrocar",
        "cotiza": "https://maps.google.com/maps?cid=5767179233935237502",
        "deleted_at": null,
        "coordinates": [
            -106.39878907976258,
            23.228152902009356
        ],
        "email": "VITRO20@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567eca3cadfb091b0c93aa"
        },
        "address": "Blvd. Rodolfo Elías Calles 523, Centro, Cd Obregón, Son.",
        "name": "Vitrocar Obregón",
        "state": "Sonora",
        "phone": "8001118487",
        "phone2": "6444125517",
        "lat": "27.483119215431834",
        "lng": -109.93513390859873,
        "sitio": "https://maps.google.com/maps?cid=17882134469546215042",
        "mx": "VITRO21",
        "client": "vitrocar",
        "cotiza": "https://maps.google.com/maps?cid=17882134469546215042",
        "deleted_at": null,
        "coordinates": [
            -109.93513390859873,
            27.483119215431834
        ],
        "email": "VITRO21@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567eca3cadfb091b0c93ab"
        },
        "address": "Nayarit 152, San Benito, Hermosillo, Son.",
        "name": "Vitrocar Hermosillo",
        "state": "Sonora",
        "phone": "8001118487",
        "phone2": "6622107118",
        "lat": "29.094192277027872",
        "lng": -110.96229462209033,
        "sitio": "https://maps.google.com/maps?cid=16655098751920539412",
        "mx": "VITRO22",
        "client": "vitrocar",
        "cotiza": "https://maps.google.com/maps?cid=16655098751920539412",
        "deleted_at": null,
        "coordinates": [
            -110.96229462209033,
            29.094192277027872
        ],
        "email": "VITRO22@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567eca3cadfb091b0c93ac"
        },
        "address": "Boulevard Adolfo Ruiz Cortines 2017, Atasta de Serra, Villahermosa, Tab.",
        "name": "Vitrocar Villahermosa ll",
        "state": "Tabasco",
        "phone": "8001118487",
        "phone2": "9933544999",
        "lat": "17.99047539430818",
        "lng": -92.94649975092646,
        "sitio": "https://maps.google.com/maps?cid=15653034789288094132",
        "mx": "VITRO23",
        "client": "vitrocar",
        "cotiza": "https://maps.google.com/maps?cid=15653034789288094132",
        "deleted_at": null,
        "coordinates": [
            -92.94649975092646,
            17.99047539430818
        ],
        "email": "VITRO23@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567eca3cadfb091b0c93ad"
        },
        "address": "Av. César López de Lara 1615, Juárez, Nuevo Laredo, Tamps.",
        "name": "Vitrocar Nuevo Laredo",
        "state": "Tamaulipas",
        "phone": "8001118487",
        "phone2": "8677130131",
        "lat": "27.485499646434107",
        "lng": -99.51723310674579,
        "sitio": "https://maps.google.com/maps?cid=2385191300694743838",
        "mx": "VITRO24",
        "client": "vitrocar",
        "cotiza": "https://maps.google.com/maps?cid=2385191300694743838",
        "deleted_at": null,
        "coordinates": [
            -99.51723310674579,
            27.485499646434107
        ],
        "email": "VITRO24@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567eca3cadfb091b0c93ae"
        },
        "address": "Av. Ignacio Zaragoza 1211, Centro, Coatzacoalcos, Ver.",
        "name": "Vitrocar Coatzacoalcos",
        "state": "Veracruz",
        "phone": "8001118487",
        "phone2": "9122145424",
        "lat": "18.144360320925912",
        "lng": -94.4313083932542,
        "sitio": "https://maps.google.com/maps?cid=8806462406139851237",
        "mx": "VITRO25",
        "client": "vitrocar",
        "cotiza": "https://maps.google.com/maps?cid=8806462406139851237",
        "deleted_at": null,
        "coordinates": [
            -94.4313083932542,
            18.144360320925912
        ],
        "email": "VITRO25@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567eca3cadfb091b0c93af"
        },
        "address": "Av. 11 801, San José, 94560 Córdoba, Ver.",
        "name": "Vitrocar Córdoba",
        "state": "Veracruz",
        "phone": "8001118487",
        "phone2": "2717179801",
        "lat": "18.893485915914304",
        "lng": -96.94111847790968,
        "sitio": "https://maps.google.com/maps?cid=11595511710635324490",
        "mx": "VITRO26",
        "client": "vitrocar",
        "cotiza": "https://maps.google.com/maps?cid=11595511710635324490",
        "deleted_at": null,
        "coordinates": [
            -96.94111847790968,
            18.893485915914304
        ],
        "email": "VITRO26@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567eca3cadfb091b0c93b0"
        },
        "address": "Av. Justo Sierra 160, Nueva Mina, Minatitlán, Ver.",
        "name": "Vitrocar Minatitlán",
        "state": "Veracruz",
        "phone": "8001118487",
        "phone2": "9222234007",
        "lat": "17.989685990973985",
        "lng": -94.56221685277937,
        "sitio": "https://maps.google.com/maps?cid=9983984696646982405",
        "mx": "VITRO27",
        "client": "vitrocar",
        "cotiza": "https://maps.google.com/maps?cid=9983984696646982405",
        "deleted_at": null,
        "coordinates": [
            -94.56221685277937,
            17.989685990973985
        ],
        "email": "VITRO27@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567eca3cadfb091b0c93b1"
        },
        "address": "Av. Juaréz, Blvd. Adolfo Ruiz Cortines Esq, 27 de Septiembre, Poza Rica de Hidalgo, Ver.",
        "name": "Vitrocar Poza Rica",
        "state": "Veracruz",
        "phone": "8001118487",
        "phone2": "7281190537",
        "lat": "20.532820145096995",
        "lng": -97.46039734907352,
        "sitio": "https://maps.google.com/maps?cid=15184121703205319569",
        "mx": "VITRO28",
        "client": "vitrocar",
        "cotiza": "https://maps.google.com/maps?cid=15184121703205319569",
        "deleted_at": null,
        "coordinates": [
            -97.46039734907352,
            20.532820145096995
        ],
        "email": "VITRO28@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567eca3cadfb091b0c93b2"
        },
        "address": "Av. Miguel Alemán 1251, Ortiz Rubio, Veracruz, Ver.",
        "name": "Vitrocar Veracruz",
        "state": "Veracruz",
        "phone": "8001118487",
        "phone2": "2299371745",
        "lat": "19.175848703251553",
        "lng": -96.13625980674583,
        "sitio": "https://maps.google.com/maps?cid=9750088873329606313",
        "mx": "VITRO29",
        "client": "vitrocar",
        "cotiza": "https://maps.google.com/maps?cid=9750088873329606313",
        "deleted_at": null,
        "coordinates": [
            -96.13625980674583,
            19.175848703251553
        ],
        "email": "VITRO29@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567eca3cadfb091b0c93b3"
        },
        "address": "C. Lázaro Cárdenas 201 A, Rafael Lucio, Xalapa-Enríquez, Ver.",
        "name": "Vitrocar Xalapa",
        "state": "Veracruz",
        "phone": "8001118487",
        "phone2": "2288426077",
        "lat": "19.56207482961797",
        "lng": -96.9264343048929,
        "sitio": "https://maps.google.com/maps?cid=17992156282889882526",
        "mx": "VITRO30",
        "client": "vitrocar",
        "cotiza": "https://maps.google.com/maps?cid=17992156282889882526",
        "deleted_at": null,
        "coordinates": [
            -96.9264343048929,
            19.56207482961797
        ],
        "email": "VITRO30@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567eca3cadfb091b0c93b4"
        },
        "address": "Calle 7 Avenida 18, Col.centro, Agua Prieta, Son.",
        "name": "Vitrocar Agua Prieta",
        "state": "Sonora",
        "phone": "6333382429",
        "phone2": "6333380858",
        "lat": "31.32741049404683",
        "lng": -109.54484543558193,
        "sitio": "https://maps.google.com/maps?cid=3703577084016930217",
        "mx": "VITRO31",
        "client": "vitrocar",
        "cotiza": "https://maps.google.com/maps?cid=3703577084016930217",
        "deleted_at": null,
        "coordinates": [
            -109.54484543558193,
            31.32741049404683
        ],
        "email": "VITRO31@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567eca3cadfb091b0c93b5"
        },
        "address": "Fco, C. Gobernador Medina Ascencio 872, Arandas, Jal.",
        "name": "Vitrocar  Arandas",
        "state": "Jalisco",
        "phone2": "3487832116",
        "lat": "20.70634895205487",
        "lng": -102.36507026441807,
        "sitio": "https://maps.google.com/maps?cid=1948245653123319810",
        "phone": "",
        "mx": "VITRO32",
        "client": "vitrocar",
        "cotiza": "https://maps.google.com/maps?cid=1948245653123319810",
        "deleted_at": null,
        "coordinates": [
            -102.36507026441807,
            20.70634895205487
        ],
        "email": "VITRO32@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567eca3cadfb091b0c93b6"
        },
        "address": "Miguel Hidalgo 2407, Centro, Nuevo Casas Grandes, Chih.",
        "name": "Vitrocar Casas Grandes",
        "state": "Chihuahua",
        "phone": "6366942828",
        "phone2": "6367000791",
        "lat": "30.42940526877863",
        "lng": -107.9168893951071,
        "sitio": "https://maps.google.com/maps?cid=3049360098568624933",
        "mx": "VITRO33",
        "client": "vitrocar",
        "cotiza": "https://maps.google.com/maps?cid=3049360098568624933",
        "deleted_at": null,
        "coordinates": [
            -107.9168893951071,
            30.42940526877863
        ],
        "email": "VITRO33@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567eca3cadfb091b0c93b7"
        },
        "address": "Calz. 16 de Septiembre 460, entre Rayón y Guerrero, Zona Centro, Cd Cuauhtémoc, Chih.",
        "name": "Vitrocar Cd. Cuauhtémoc",
        "state": "Chihuahua",
        "phone": "6255820122",
        "phone2": "6255824077",
        "lat": "28.409922210167707",
        "lng": -106.85877312209031,
        "sitio": "https://maps.google.com/maps?cid=249117880196095505",
        "mx": "VITRO34",
        "client": "vitrocar",
        "cotiza": "https://maps.google.com/maps?cid=249117880196095505",
        "deleted_at": null,
        "coordinates": [
            -106.85877312209031,
            28.409922210167707
        ],
        "email": "VITRO34@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567eca3cadfb091b0c93b8"
        },
        "address": "Blvrd Camino Real 775, El Diezmo, Colima, Col.",
        "name": "Vitrocar Colima",
        "state": "Colima",
        "phone": "3123238080",
        "phone2": "3121570904",
        "lat": "19.2547657016076",
        "lng": -103.6962013681239,
        "sitio": "https://maps.google.com/maps?cid=15624437813747255747",
        "mx": "VITRO35",
        "client": "vitrocar",
        "cotiza": "https://maps.google.com/maps?cid=15624437813747255747",
        "deleted_at": null,
        "coordinates": [
            -103.6962013681239,
            19.2547657016076
        ],
        "email": "VITRO35@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567eca3cadfb091b0c93b9"
        },
        "address": "Porras 301 Sur, Zona Centro, 34000 Durango, Dgo.",
        "name": "Vitrocar Durango",
        "state": "Durango",
        "phone": "6188111251",
        "phone2": "6182104926",
        "lat": "24.02702147997601",
        "lng": -104.65522227790966,
        "sitio": "https://maps.google.com/maps?cid=9374336111710236195",
        "mx": "VITRO36",
        "client": "vitrocar",
        "cotiza": "https://maps.google.com/maps?cid=9374336111710236195",
        "deleted_at": null,
        "coordinates": [
            -104.65522227790966,
            24.02702147997601
        ],
        "email": "VITRO36@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567eca3cadfb091b0c93ba"
        },
        "address": "Av. Reforma 1066, Ulbrich, 22830 Ensenada, B.C.",
        "name": "Vitrocar Ensenada",
        "state": "Baja California",
        "phone": "6461521666",
        "phone2": "6462269868",
        "lat": "31.869235188301587",
        "lng": -116.60803520674578,
        "sitio": "https://maps.google.com/maps?cid=6481794273439160221",
        "mx": "VITRO37",
        "client": "vitrocar",
        "cotiza": "https://maps.google.com/maps?cid=6481794273439160221",
        "deleted_at": null,
        "coordinates": [
            -116.60803520674578,
            31.869235188301587
        ],
        "email": "VITRO37@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567eca3cadfb091b0c93bb"
        },
        "address": "Calle Gral. Nicolás Bravo 257, Col del Bosque, Guasave, Sin.",
        "name": "Vitrocar Guasave",
        "state": "Sinaloa",
        "phone": "6878710957",
        "phone2": "6878710957",
        "lat": "25.568115760152534",
        "lng": -108.4700694932542,
        "sitio": "https://maps.google.com/maps?cid=14291483501499576365",
        "mx": "VITRO38",
        "client": "vitrocar",
        "cotiza": "https://maps.google.com/maps?cid=14291483501499576365",
        "deleted_at": null,
        "coordinates": [
            -108.4700694932542,
            25.568115760152534
        ],
        "email": "VITRO38@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567eca3cadfb091b0c93bc"
        },
        "address": "Calle 10 Ave 12 y 13 65, C. Diez, Centro, Heróica Guaymas, Son.",
        "name": "Vitrocar Guaymas",
        "state": "Sonora",
        "phone": "6222222888",
        "phone2": "6222228828",
        "lat": "27.922011738080926",
        "lng": -110.90233349814838,
        "sitio": "https://maps.google.com/maps?cid=12480276681497503977",
        "mx": "VITRO39",
        "client": "vitrocar",
        "cotiza": "https://maps.google.com/maps?cid=12480276681497503977",
        "deleted_at": null,
        "coordinates": [
            -110.90233349814838,
            27.922011738080926
        ],
        "email": "VITRO39@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567f063cadfb091b0c93bd"
        },
        "address": "Blvrd Jiquilpan 1463-Pte, Scally, Los Mochis, Sin.",
        "name": "Vitrocar Jiquilpan",
        "state": "Sinaloa",
        "phone": "6688158525",
        "phone2": "6688158525",
        "lat": "25.807953768346028",
        "lng": -108.99751708465622,
        "sitio": "https://maps.google.com/maps?cid=7231659277139624383",
        "mx": "VITRO40",
        "client": "vitrocar",
        "cotiza": "https://maps.google.com/maps?cid=7231659277139624383",
        "deleted_at": null,
        "coordinates": [
            -108.99751708465622,
            25.807953768346028
        ],
        "email": "VITRO40@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567f063cadfb091b0c93be"
        },
        "address": "Mariano Abasolo Sn, Barrio Manglito, La Paz, B.C.S.",
        "name": "Vitrocar La Paz",
        "state": "Baja California Sur",
        "phone": "6121295000",
        "phone2": "6121295000",
        "lat": "24.147695040061258",
        "lng": -110.32918759999998,
        "sitio": "https://maps.google.com/maps?cid=9449902546631320873",
        "mx": "VITRO41",
        "client": "vitrocar",
        "cotiza": "https://maps.google.com/maps?cid=9449902546631320873",
        "deleted_at": null,
        "coordinates": [
            -110.32918759999998,
            24.147695040061258
        ],
        "email": "VITRO41@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567f063cadfb091b0c93bf"
        },
        "address": "Norte 1, Puentes Cuates, Col. Las Arboledas, Lázaro Cárdenas, Mich.",
        "name": "Vitrocar Lázaro Cárdenas",
        "state": "Michoacán",
        "phone": "7535321200",
        "phone2": "7535373568",
        "lat": "17.974165615051014",
        "lng": -102.20342038650784,
        "sitio": "https://maps.google.com/maps?cid=14059554090930356144",
        "mx": "VITRO42",
        "client": "vitrocar",
        "cotiza": "https://maps.google.com/maps?cid=14059554090930356144",
        "deleted_at": null,
        "coordinates": [
            -102.20342038650784,
            17.974165615051014
        ],
        "email": "VITRO42@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567f063cadfb091b0c93c0"
        },
        "address": "Av. Aquiles Serdán 22-Pte, Centro, Los Mochis, Sin.",
        "name": "Vitrocar Los Mochis",
        "state": "Sinaloa",
        "phone": "6688128525",
        "phone2": "6688128525",
        "lat": "25.792869458932532",
        "lng": -108.98726252883596,
        "sitio": "https://maps.google.com/maps?cid=9525728615423803858",
        "mx": "VITRO43",
        "client": "vitrocar",
        "cotiza": "https://maps.google.com/maps?cid=9525728615423803858",
        "deleted_at": null,
        "coordinates": [
            -108.98726252883596,
            25.792869458932532
        ],
        "email": "VITRO43@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567f063cadfb091b0c93c1"
        },
        "address": "Av. 5 de Febrero 265, Centro, 84060 Nogales, Son.",
        "name": "Vitrocar Nogales",
        "state": "Sonora",
        "phone": "6313137420",
        "phone2": "6313100132",
        "lat": "31.315213454005622",
        "lng": -110.95050817301566,
        "sitio": "https://maps.google.com/maps?cid=15815879220397631902",
        "mx": "VITRO44",
        "client": "vitrocar",
        "cotiza": "https://maps.google.com/maps?cid=15815879220397631902",
        "deleted_at": null,
        "coordinates": [
            -110.95050817301566,
            31.315213454005622
        ],
        "email": "VITRO44@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567f063cadfb091b0c93c2"
        },
        "address": "No Reelección 1812, Col.Juárez, 85870 Navojoa, Son.",
        "name": "Vitrocar Navojoa",
        "state": "Sonora",
        "phone": "6424223202",
        "phone2": "6424223202",
        "lat": "27.065311762572872",
        "lng": -109.44382426931243,
        "sitio": "https://maps.google.com/maps?cid=2930701204261892797",
        "mx": "VITRO45",
        "client": "vitrocar",
        "cotiza": "https://maps.google.com/maps?cid=2930701204261892797",
        "deleted_at": null,
        "coordinates": [
            -109.44382426931243,
            27.065311762572872
        ],
        "email": "VITRO45@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567f063cadfb091b0c93c3"
        },
        "address": "Av. Francisco Zarco 1, Centro, 47800 Ocotlán, Jal.",
        "name": "Vitrocar Ocotlán",
        "state": "Jalisco",
        "phone": "39292558",
        "phone2": "39292558",
        "lat": "20.347509887460813",
        "lng": -102.77755774232811,
        "sitio": "https://maps.google.com/maps?cid=16493363232780661349",
        "mx": "VITRO46",
        "client": "vitrocar",
        "cotiza": "https://maps.google.com/maps?cid=16493363232780661349",
        "deleted_at": null,
        "coordinates": [
            -102.77755774232811,
            20.347509887460813
        ],
        "email": "VITRO46@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567f063cadfb091b0c93c4"
        },
        "address": "Blvd. Miguel Hidalgo 1215-A, Longoria, Reynosa, Tamps.",
        "name": "Vitrocar Reynosa",
        "state": "Tamaulipas",
        "phone": "8999231335",
        "phone2": "8999231336",
        "lat": "26.083497129543577",
        "lng": -98.29765377301568,
        "sitio": "https://maps.google.com/maps?cid=16849213616104044520",
        "mx": "VITRO47",
        "client": "vitrocar",
        "cotiza": "https://maps.google.com/maps?cid=16849213616104044520",
        "deleted_at": null,
        "coordinates": [
            -98.29765377301568,
            26.083497129543577
        ],
        "email": "VITRO47@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567f063cadfb091b0c93c5"
        },
        "address": "Av Francisco I. Madero 1137, Río Bravo 1, Río Bravo, Cd Río Bravo, Tamps.",
        "name": "Vitrocar Río Bravo",
        "state": "Tamaulipas",
        "phone": "8998532610",
        "phone2": "8999229950",
        "lat": "25.98739134552986",
        "lng": -98.10790865767187,
        "sitio": "https://maps.google.com/maps?cid=707642015293003762",
        "mx": "VITRO48",
        "client": "vitrocar",
        "cotiza": "https://maps.google.com/maps?cid=707642015293003762",
        "deleted_at": null,
        "coordinates": [
            -98.10790865767187,
            25.98739134552986
        ],
        "email": "VITRO48@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567f063cadfb091b0c93c6"
        },
        "address": "Transpeninsular Km. 170 Chula Vista Vicente Guerrero, Baja California, B.C.",
        "name": "Vitrocar San Quintín",
        "state": "Baja California",
        "phone": "6161236164",
        "phone2": "6461521666",
        "lat": "",
        "lng": -108.4700694932542,
        "sitio": "https://maps.google.com/maps?cid=16826441137552504294",
        "mx": "VITRO49",
        "client": "vitrocar",
        "cotiza": "https://maps.google.com/maps?cid=16826441137552504294",
        "deleted_at": null,
        "coordinates": [
            -108.4700694932542,
            null
        ],
        "email": "VITRO49@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567f063cadfb091b0c93c7"
        },
        "address": "18 Pte. 219, Jacarandas, 75719 Tehuacán, Pue.",
        "name": "Vitrocar Tehuacán",
        "state": "Puebla",
        "phone": "2383831945",
        "phone2": "2484847707",
        "lat": "18.4757393171088",
        "lng": -97.39756473068756,
        "sitio": "https://maps.google.com/maps?cid=4631211150700347320",
        "mx": "VITRO50",
        "client": "vitrocar",
        "cotiza": "https://maps.google.com/maps?cid=4631211150700347320",
        "deleted_at": null,
        "coordinates": [
            -97.39756473068756,
            18.4757393171088
        ],
        "email": "VITRO50@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567f063cadfb091b0c93c8"
        },
        "address": "Blvd. Lic. Anacleto Glez F. Sur 210, San Miguel,Tepatitlán de Morelos, Jal.",
        "name": "Vitrocar Tepatitlán",
        "state": "Jalisco",
        "phone": "3787825816",
        "phone2": "3781208769",
        "lat": "20.811507478540857",
        "lng": -102.75289852883596,
        "sitio": "https://maps.google.com/maps?cid=11041448776116880042",
        "mx": "VITRO51",
        "client": "vitrocar",
        "cotiza": "https://maps.google.com/maps?cid=11041448776116880042",
        "deleted_at": null,
        "coordinates": [
            -102.75289852883596,
            20.811507478540857
        ],
        "email": "VITRO51@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567f063cadfb091b0c93c9"
        },
        "address": "A Nautla 27, Novena del Carmen, Barrio de Chignaulingo, Teziutlán, Pue.",
        "name": "Vitrocar Teziutlán",
        "state": "Puebla",
        "phone": "2313123671",
        "phone2": "2313123671",
        "lat": "19.82216159730505",
        "lng": -97.35492012883594,
        "sitio": "https://maps.google.com/maps?cid=6030825944789029198",
        "mx": "VITRO52",
        "client": "vitrocar",
        "cotiza": "https://maps.google.com/maps?cid=6030825944789029198",
        "deleted_at": null,
        "coordinates": [
            -97.35492012883594,
            19.82216159730505
        ],
        "email": "VITRO52@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567f063cadfb091b0c93ca"
        },
        "address": "Ocotlán - Sta. Ana 9, Miraflores, Tlaxcala de Xicohténcatl, Tlax.",
        "name": "Vitrocar Tlaxcala",
        "state": "Tlaxcala",
        "phone": "2464660113",
        "phone2": "7442592358",
        "lat": "19.316302673675086",
        "lng": -98.22366897116403,
        "sitio": "https://maps.google.com/maps?cid=9180619220657872885",
        "mx": "VITRO53",
        "client": "vitrocar",
        "cotiza": "https://maps.google.com/maps?cid=9180619220657872885",
        "deleted_at": null,
        "coordinates": [
            -98.22366897116403,
            19.316302673675086
        ],
        "email": "VITRO53@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567f063cadfb091b0c93cb"
        },
        "address": "Av. Ejército Mexicano 105, Guadalupe, Tampico, Tamps.",
        "name": "Vitrocar Tampico",
        "state": "Tamaulipas",
        "phone": "8332137756",
        "phone2": "8332175070",
        "lat": "22.247010094420997",
        "lng": -97.8727300423281,
        "sitio": "https://maps.google.com/maps?cid=9041487479069285359",
        "mx": "VITRO54",
        "client": "vitrocar",
        "cotiza": "https://maps.google.com/maps?cid=9041487479069285359",
        "deleted_at": null,
        "coordinates": [
            -97.8727300423281,
            22.247010094420997
        ],
        "email": "VITRO54@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567f063cadfb091b0c93cc"
        },
        "address": "Carr. Tampico Mante 2302 B, Niños Héroes, Tampico, Tamps.",
        "name": "Vitrocar Tampico 2",
        "state": "Tamaulipas",
        "phone": "8332276343",
        "phone2": "8332276357",
        "lat": "22.319090025920403",
        "lng": -97.87809142883594,
        "sitio": "https://maps.google.com/maps?cid=17721102306186601042",
        "mx": "VITRO55",
        "client": "vitrocar",
        "cotiza": "https://maps.google.com/maps?cid=17721102306186601042",
        "deleted_at": null,
        "coordinates": [
            -97.87809142883594,
            22.319090025920403
        ],
        "email": "VITRO55@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567f063cadfb091b0c93cd"
        },
        "address": "18 de Marzo 90, San Pedrito, San Pedro Tlaquepaque, Jal.",
        "name": "Vitrocar Tlaquepaque",
        "state": "Jalisco",
        "phone": "3311361403",
        "phone2": "3336000420",
        "lat": "20.61455584436321",
        "lng": -103.28495561349214,
        "sitio": "https://maps.google.com/maps?cid=9348054367698192784",
        "mx": "VITRO56",
        "client": "vitrocar",
        "cotiza": "https://maps.google.com/maps?cid=9348054367698192784",
        "deleted_at": null,
        "coordinates": [
            -103.28495561349214,
            20.61455584436321
        ],
        "email": "VITRO56@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567f063cadfb091b0c93ce"
        },
        "address": "Pedro Argüelles 10, Lucero, Heróica Matamoros, Tamps.",
        "name": "Vitrocar Matamoros",
        "state": "Tamaulipas",
        "phone": "8688124419",
        "phone2": "8688137440",
        "lat": "25.868296906691025",
        "lng": -97.50302424232812,
        "sitio": "https://maps.google.com/maps?cid=15739956742968209564",
        "mx": "VITRO57",
        "client": "vitrocar",
        "cotiza": "https://maps.google.com/maps?cid=15739956742968209564",
        "deleted_at": null,
        "coordinates": [
            -97.50302424232812,
            25.868296906691025
        ],
        "email": "VITRO57@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567f063cadfb091b0c93cf"
        },
        "address": "Calle 19 172, Col.Miraflores, 97179 Mérida, Yuc.",
        "name": "Vitrocar Mérida III",
        "state": "Yucatán",
        "phone": "9999294718",
        "phone2": "9991312681",
        "lat": "20.957363353878716",
        "lng": -89.59229643068758,
        "sitio": "https://maps.google.com/maps?cid=6619684756213567911",
        "mx": "VITRO58",
        "client": "vitrocar",
        "cotiza": "https://maps.google.com/maps?cid=6619684756213567911",
        "deleted_at": null,
        "coordinates": [
            -89.59229643068758,
            20.957363353878716
        ],
        "email": "VITRO58@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567f063cadfb091b0c93d0"
        },
        "address": "Calle 86 B 523-C, entre 65 y 65-A, Centro, Mérida, Yuc.",
        "name": "Vitrocar Mérida Avenida",
        "state": "Yucatán",
        "phone": "9999239883",
        "phone2": "9999234549",
        "lat": "20.964331347139957",
        "lng": -89.62110542883597,
        "sitio": "https://maps.google.com/maps?cid=13857866011696610666",
        "mx": "VITRO59",
        "client": "vitrocar",
        "cotiza": "https://maps.google.com/maps?cid=13857866011696610666",
        "deleted_at": null,
        "coordinates": [
            -89.62110542883597,
            20.964331347139957
        ],
        "email": "VITRO59@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567fea3cadfb091b0c93d1"
        },
        "address": "Av. Itzáes 542, entre 73 y 77, Sambulá, 97250 Mérida, Yuc.",
        "name": "Vitrocar Mérida Aviación",
        "state": "Yucatán",
        "phone": "9999840724",
        "phone2": "9999840712",
        "lat": "20.959786108240486",
        "lng": -89.6421739423281,
        "sitio": "https://maps.google.com/maps?cid=14464551408105447601",
        "mx": "VITRO60",
        "client": "vitrocar",
        "cotiza": "https://maps.google.com/maps?cid=14464551408105447601",
        "deleted_at": null,
        "coordinates": [
            -89.6421739423281,
            20.959786108240486
        ],
        "email": "VITRO60@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567fea3cadfb091b0c93d2"
        },
        "address": "C. 60 316A, Alcalá Martín, 97050 Mérida, Yuc.",
        "name": "Vitrocar Mérida Parabrisas Estadio",
        "state": "Yucatán",
        "phone": "9999255555",
        "phone2": "9999255555",
        "lat": "20.994415465227387",
        "lng": -89.62062692883597,
        "sitio": "https://maps.google.com/maps?cid=8492243064020031882",
        "mx": "VITRO61",
        "client": "vitrocar",
        "cotiza": "https://maps.google.com/maps?cid=8492243064020031882",
        "deleted_at": null,
        "coordinates": [
            -89.62062692883597,
            20.994415465227387
        ],
        "email": "VITRO61@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567fea3cadfb091b0c93d3"
        },
        "address": "Av. Jacinto Canek 642 A, entre 108ª Y 110, Bojórquez, Mérida, Yuc.",
        "name": "Vitrocar Mérida Jacinto Canek",
        "state": "Yucatán",
        "phone": "9999456004",
        "phone2": "9999456005",
        "lat": "20.98321594727484",
        "lng": -89.65320026931245,
        "sitio": "https://www.google.com/maps?cid=1436639591013054345",
        "mx": "VITRO62",
        "client": "vitrocar",
        "cotiza": "https://www.google.com/maps?cid=1436639591013054345",
        "deleted_at": null,
        "coordinates": [
            -89.65320026931245,
            20.98321594727484
        ],
        "email": "VITRO62@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567fea3cadfb091b0c93d4"
        },
        "address": "Calle 50 490, Francisco de Montejo, Mérida, Yuc.",
        "name": "Vitrocar Mérida Francisco de Montejo",
        "state": "Yucatán",
        "phone": "9999195228",
        "phone2": "9999195228",
        "lat": "21.02213240979737",
        "lng": -89.6501605,
        "sitio": "https://maps.google.com/maps?cid=3426941310775893253",
        "mx": "VITRO63",
        "client": "vitrocar",
        "cotiza": "https://maps.google.com/maps?cid=3426941310775893253",
        "deleted_at": null,
        "coordinates": [
            -89.6501605,
            21.02213240979737
        ],
        "email": "VITRO63@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567fea3cadfb091b0c93d5"
        },
        "address": "Carlos A. Madrazo 102, Independencia, Macuspana, Tab.",
        "name": "Vitrocar Macuspana",
        "state": "Tabasco",
        "phone": "9363621234",
        "phone2": "9361226557",
        "lat": "17.75078979730962",
        "lng": -92.60624344232812,
        "sitio": "https://maps.google.com/maps?cid=211495011804048805",
        "mx": "VITRO64",
        "client": "vitrocar",
        "cotiza": "https://maps.google.com/maps?cid=211495011804048805",
        "deleted_at": null,
        "coordinates": [
            -92.60624344232812,
            17.75078979730962
        ],
        "email": "VITRO64@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567fea3cadfb091b0c93d6"
        },
        "address": "AV. Luis Donaldo Colosio 128, Cd del Carmen, Camp.",
        "name": "Vitrocar Cd. Del Carmen",
        "state": "Campeche",
        "phone": "9383813842",
        "phone2": "9381198858",
        "lat": "18.633145296564848",
        "lng": -91.82472171349214,
        "sitio": "https://maps.google.com/maps?cid=4224097689915603697",
        "mx": "VITRO65",
        "client": "vitrocar",
        "cotiza": "https://maps.google.com/maps?cid=4224097689915603697",
        "deleted_at": null,
        "coordinates": [
            -91.82472171349214,
            18.633145296564848
        ],
        "email": "VITRO65@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567fea3cadfb091b0c93d7"
        },
        "address": "Calle 4 poniente. 116, Centro, 33000 Delicias, Chih.",
        "name": "Vitrocar Cd. Delicias",
        "state": "Chihuahua",
        "phone": "6394790992",
        "phone2": "6394741068",
        "lat": "28.202692271787647",
        "lng": -105.45959363068756,
        "sitio": "https://maps.google.com/maps?cid=2513332061266586320",
        "mx": "VITRO66",
        "client": "vitrocar",
        "cotiza": "https://maps.google.com/maps?cid=2513332061266586320",
        "deleted_at": null,
        "coordinates": [
            -105.45959363068756,
            28.202692271787647
        ],
        "email": "VITRO66@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567fea3cadfb091b0c93d8"
        },
        "address": "Calle 6. 108 x 11A y 13, Felipe Carrillo Puerto, Mérida, Yuc.",
        "name": "Vitrocar Mérida Felipe Carrillo Puerto",
        "state": "Yucatán",
        "phone": "9999261593",
        "phone2": "9999275011",
        "lat": "20.99551097972095",
        "lng": -89.6013127423281,
        "sitio": "https://maps.google.com/maps?cid=15569436009545903690",
        "mx": "VITRO67",
        "client": "vitrocar",
        "cotiza": "https://maps.google.com/maps?cid=15569436009545903690",
        "deleted_at": null,
        "coordinates": [
            -89.6013127423281,
            20.99551097972095
        ],
        "email": "VITRO67@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567fea3cadfb091b0c93d9"
        },
        "address": "Perif. Paseo de la República 296, 58188 Morelia, Mich.",
        "name": "Vitrocar Morelia",
        "state": "Michoacán",
        "phone": "3311361403",
        "phone2": "4434459978",
        "lat": "19.696368220122142",
        "lng": -101.24151042698432,
        "sitio": "https://maps.google.com/maps?cid=9569952021804918572",
        "mx": "VITRO68",
        "client": "vitrocar",
        "cotiza": "https://maps.google.com/maps?cid=9569952021804918572",
        "deleted_at": null,
        "coordinates": [
            -101.24151042698432,
            19.696368220122142
        ],
        "email": "VITRO68@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567fea3cadfb091b0c93da"
        },
        "address": "Lázaro Cárdenas S/N, Col Pakal-Na, Palenque",
        "name": "Vitrocar Palenque",
        "state": "Chiapas",
        "phone": "9161245003",
        "phone2": "9163454577",
        "lat": "17.54284463167586",
        "lng": -91.98800818650756,
        "sitio": "https://goo.gl/maps/fde7uNyGZcccmUa46",
        "mx": "VITRO69",
        "client": "vitrocar",
        "cotiza": "https://goo.gl/maps/fde7uNyGZcccmUa46",
        "deleted_at": null,
        "coordinates": [
            -91.98800818650756,
            17.54284463167586
        ],
        "email": "VITRO69@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567fea3cadfb091b0c93db"
        },
        "address": "C. Xicoténcatl 1877, Benito Juárez, Cd Acuña, Coah.",
        "name": "Vitrocar Acuña",
        "state": "Coahuila",
        "phone": "8777727719",
        "phone2": "8787830363",
        "lat": "29.333367037489797",
        "lng": -100.95093535582026,
        "sitio": "https://goo.gl/maps/1eR9nxW6DseiVyXi9",
        "mx": "VITRO70",
        "client": "vitrocar",
        "cotiza": "https://goo.gl/maps/1eR9nxW6DseiVyXi9",
        "deleted_at": null,
        "coordinates": [
            -100.95093535582026,
            29.333367037489797
        ],
        "email": "VITRO70@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567fea3cadfb091b0c93dc"
        },
        "address": "Durango 201, Brisas Del Balsas, Cd Altamirano, Gro.",
        "name": "Vitrocar Altamirano",
        "state": "Guerrero",
        "phone": "7676725080",
        "phone2": "7676725758",
        "lat": "16.8627024479886",
        "lng": -99.8986759,
        "sitio": "https://goo.gl/maps/YTqdXBBeiZchTXYV7",
        "mx": "VITRO71",
        "client": "vitrocar",
        "cotiza": "https://goo.gl/maps/YTqdXBBeiZchTXYV7",
        "deleted_at": null,
        "coordinates": [
            -99.8986759,
            16.8627024479886
        ],
        "email": "VITRO71@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567fea3cadfb091b0c93dd"
        },
        "address": "Blvd. Libertad. 2470 90300, Prol. Blvd. la Libertad 2470, Jardines de Apizaco,Apizaco, Tlax.",
        "name": "Vitrocar Apizaco",
        "state": "Tlaxcala",
        "phone": "2411130599",
        "phone2": "2414178580",
        "lat": "19.408101267586986",
        "lng": -98.15699785135178,
        "sitio": "https://goo.gl/maps/rSHNy11FkEn5yw7Y9",
        "mx": "VITRO72",
        "client": "vitrocar",
        "cotiza": "https://goo.gl/maps/rSHNy11FkEn5yw7Y9",
        "deleted_at": null,
        "coordinates": [
            -98.15699785135178,
            19.408101267586986
        ],
        "email": "VITRO72@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567fea3cadfb091b0c93de"
        },
        "address": "Blvd. Francisco I. Madero 911, San Miguel, Apizaco, Tlax.",
        "name": "Vitrocar Apizaco Centro",
        "state": "Tlaxcala",
        "phone": "2414178580",
        "phone2": "",
        "lat": "19.41676936711029",
        "lng": -98.14583766405393,
        "sitio": "https://goo.gl/maps/JWrbYGnA8pMdFnvT8",
        "mx": "VITRO73",
        "client": "vitrocar",
        "cotiza": "https://goo.gl/maps/JWrbYGnA8pMdFnvT8",
        "deleted_at": null,
        "coordinates": [
            -98.14583766405393,
            19.41676936711029
        ],
        "email": "VITRO73@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567fea3cadfb091b0c93df"
        },
        "address": "Prol. Guadalupe Victoria 2953, Cerro Colorado, Autlán de Navarro, Jal.",
        "name": "Vitrocar Autlán",
        "state": "Jalisco",
        "phone": "3173820138",
        "phone2": "3173822200",
        "lat": "19.749143402814198",
        "lng": -104.36396881804734,
        "sitio": "https://goo.gl/maps/GSumrPt6B9icZN2MA",
        "mx": "VITRO74",
        "client": "vitrocar",
        "cotiza": "https://goo.gl/maps/GSumrPt6B9icZN2MA",
        "deleted_at": null,
        "coordinates": [
            -104.36396881804734,
            19.749143402814198
        ],
        "email": "VITRO74@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567fea3cadfb091b0c93e0"
        },
        "address": "Av. Manuel Ávila Camacho 1225, Mezquitán Country, Guadalajara, Jal.",
        "name": "Vitrocar Ávila Camacho",
        "state": "Jalisco",
        "phone": "8001118487",
        "phone2": "",
        "lat": "20.744436459044994",
        "lng": -103.34530306637488,
        "sitio": "https://goo.gl/maps/pAgGwmuHdtwjn4gz6",
        "mx": "VITRO75",
        "client": "vitrocar",
        "cotiza": "https://goo.gl/maps/pAgGwmuHdtwjn4gz6",
        "deleted_at": null,
        "coordinates": [
            -103.34530306637488,
            20.744436459044994
        ],
        "email": "VITRO75@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567fea3cadfb091b0c93e1"
        },
        "address": "C. 18 64, Lázaro Cárdenas, Lázaro Cárdenas,Caborca, Son.",
        "name": "Vitrocar Caborca",
        "state": "Sonora",
        "phone": "6373728457",
        "phone2": "6373728457",
        "lat": "30.726312831409356",
        "lng": -112.14756679999998,
        "sitio": "https://goo.gl/maps/yjVyLRvFYHJBerKd6",
        "mx": "VITRO76",
        "client": "vitrocar",
        "cotiza": "https://goo.gl/maps/yjVyLRvFYHJBerKd6",
        "deleted_at": null,
        "coordinates": [
            -112.14756679999998,
            30.726312831409356
        ],
        "email": "VITRO76@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567fea3cadfb091b0c93e2"
        },
        "address": "Av. Francisco I Madero No. 226 entre Av. Gobernadores y Privada Francisco y Madero col. San Francisco  c.p. 24010  Campeche, Campeche",
        "name": "Vitrocar Campeche Matriz",
        "state": "Campeche",
        "phone": "9811271276",
        "phone2": "9818116791",
        "lat": "19.850957509175615",
        "lng": -90.52134305767188,
        "sitio": "https://goo.gl/maps/sMxfaSeaSg7MLf499",
        "mx": "VITRO77",
        "client": "vitrocar",
        "cotiza": "https://goo.gl/maps/sMxfaSeaSg7MLf499",
        "deleted_at": null,
        "coordinates": [
            -90.52134305767188,
            19.850957509175615
        ],
        "email": "VITRO77@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567fea3cadfb091b0c93e3"
        },
        "address": "Av. López Portillo 142 Manzana 45 Lotes 15 y 16, Cancún, Q.R.",
        "name": "Vitrocar Cancún",
        "state": "Quintana Roo",
        "phone": "9988845686",
        "phone2": "9988876084",
        "lat": "21.28275363768298",
        "lng": -86.80082944944333,
        "sitio": "https://goo.gl/maps/QUaxTafZSBijmwEZ7",
        "mx": "VITRO78",
        "client": "vitrocar",
        "cotiza": "https://goo.gl/maps/QUaxTafZSBijmwEZ7",
        "deleted_at": null,
        "coordinates": [
            -86.80082944944333,
            21.28275363768298
        ],
        "email": "VITRO78@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "65567fea3cadfb091b0c93e4"
        },
        "address": "C. Moctezuma No.317, Centro, Heróica Cárdenas, Tab.",
        "name": "Vitrocar Cárdenas",
        "state": "Tabasco",
        "phone": "9373720182",
        "phone2": "9373225112",
        "lat": "18.348220141893044",
        "lng": -93.37337359761989,
        "sitio": "https://goo.gl/maps/DUiaKZugdAEs6BQF7",
        "mx": "VITRO79",
        "client": "vitrocar",
        "cotiza": "https://goo.gl/maps/DUiaKZugdAEs6BQF7",
        "deleted_at": null,
        "coordinates": [
            -93.37337359761989,
            18.348220141893044
        ],
        "email": "VITRO79@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "655680183cadfb091b0c93e5"
        },
        "address": "Blvd. Emilio Portes Gil No.1937, Enrique Cárdenas González,Cd Victoria, Tamps.",
        "name": "Vitrocar Cd. Victoria II",
        "state": "Tamaulipas",
        "phone": "8343130840",
        "phone2": "",
        "lat": "23.753201259869815",
        "lng": -99.16419461349216,
        "sitio": "https://goo.gl/maps/Nug8KnrZSWpPL2qn6",
        "mx": "VITRO80",
        "client": "vitrocar",
        "cotiza": "https://goo.gl/maps/Nug8KnrZSWpPL2qn6",
        "deleted_at": null,
        "coordinates": [
            -99.16419461349216,
            23.753201259869815
        ],
        "email": "VITRO80@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "655680183cadfb091b0c93e6"
        },
        "address": "Panamericana Km 56.8, Sur, Celaya, Gto.",
        "name": "Vitrocar Celaya II",
        "state": "Guanajuato",
        "phone": "4616145372",
        "phone2": "4612160703",
        "lat": "20.60032106028375,",
        "lng": -100.81065450207153,
        "sitio": "https://goo.gl/maps/YiSBiX26i8DGt5TC9",
        "mx": "VITRO81",
        "client": "vitrocar",
        "cotiza": "https://goo.gl/maps/YiSBiX26i8DGt5TC9",
        "deleted_at": null,
        "coordinates": [
            -100.81065450207153,
            20.60032106028375
        ],
        "email": "VITRO81@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "655680183cadfb091b0c93e7"
        },
        "address": "Av. Tecnológico No. 50, Valle Hermoso, Celaya, Gto.",
        "name": "Vitrocar Celaya III",
        "state": "Guanajuato",
        "phone": "4616115091",
        "phone2": "",
        "lat": "20.5410503259692",
        "lng": -100.81997503073711,
        "sitio": "https://goo.gl/maps/NstNdenk39N6gaj76",
        "mx": "VITRO82",
        "client": "vitrocar",
        "cotiza": "https://goo.gl/maps/NstNdenk39N6gaj76",
        "deleted_at": null,
        "coordinates": [
            -100.81997503073711,
            20.5410503259692
        ],
        "email": "VITRO82@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "655680183cadfb091b0c93e8"
        },
        "address": "Nardo #1113, Col. Las Flores, 38090 Celaya, Gto.",
        "name": "Vitrocar Celaya IV",
        "state": "Guanajuato",
        "phone": "4616167725",
        "phone2": "4616167725",
        "lat": "20.50596328174995",
        "lng": -100.81362203246336,
        "sitio": "https://goo.gl/maps/q6fVu6Fe3nUDT5Hn8",
        "mx": "VITRO83",
        "client": "vitrocar",
        "cotiza": "https://goo.gl/maps/q6fVu6Fe3nUDT5Hn8",
        "deleted_at": null,
        "coordinates": [
            -100.81362203246336,
            20.50596328174995
        ],
        "email": "VITRO83@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "655680183cadfb091b0c93e9"
        },
        "address": "Calle Padre Insurgentes 325 Col. Zona Centro, Celaya Guanajuato, C.P.38000",
        "name": "Vitrocar Celaya Centro",
        "state": "Guanajuato",
        "phone": "4616136307",
        "phone2": "4616136699",
        "lat": "20.524114947866977",
        "lng": -100.79090028650782,
        "sitio": "https://goo.gl/maps/71hKABxfKyWry8a96",
        "mx": "VITRO84",
        "client": "vitrocar",
        "cotiza": "https://goo.gl/maps/71hKABxfKyWry8a96",
        "deleted_at": null,
        "coordinates": [
            -100.79090028650782,
            20.524114947866977
        ],
        "email": "VITRO84@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "655680183cadfb091b0c93ea"
        },
        "address": "Carlos A. Madrazo, Av. Javier Rojo Gómez 347, Plutarco Elías Calles, 77028 Chetumal, Q.R.",
        "name": "Vitrocar Chetumal",
        "state": "Quintana Roo",
        "phone": "9836882362",
        "phone2": "9988876084",
        "lat": "18.515033364540265",
        "lng": -88.31830412883593,
        "sitio": "https://goo.gl/maps/unRx2P38x6YGwNE47",
        "mx": "VITRO85",
        "client": "vitrocar",
        "cotiza": "https://goo.gl/maps/unRx2P38x6YGwNE47",
        "deleted_at": null,
        "coordinates": [
            -88.31830412883593,
            18.515033364540265
        ],
        "email": "VITRO85@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "655680183cadfb091b0c93eb"
        },
        "address": "Lázaro Cárdenas No. 88, Col. La Haciendita, Chilpancingo de los Bravo, Gro.",
        "name": "Vitrocar Chilpancingo",
        "state": "Guerrero",
        "phone": "7471161107",
        "phone2": "",
        "lat": "17.5362553116178",
        "lng": -99.49703041349215,
        "sitio": "https://goo.gl/maps/7Jweqo1vZ2e6vSZQA",
        "mx": "VITRO86",
        "client": "vitrocar",
        "cotiza": "https://goo.gl/maps/7Jweqo1vZ2e6vSZQA",
        "deleted_at": null,
        "coordinates": [
            -99.49703041349215,
            17.5362553116178
        ],
        "email": "VITRO86@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "655680183cadfb091b0c93ec"
        },
        "address": "Aquiles serdan #600 colonia santa cecilia cihuatlan jalisco ",
        "name": "Vitrocar Cihuatlán",
        "state": "Jalisco",
        "phone": "3153553551",
        "phone2": "0",
        "lat": "19.23352915012199",
        "lng": -104.57437010370325,
        "sitio": "https://goo.gl/maps/9F2Grf8tF7ybn6nz5",
        "mx": "VITRO87",
        "client": "vitrocar",
        "cotiza": "https://goo.gl/maps/9F2Grf8tF7ybn6nz5",
        "deleted_at": null,
        "coordinates": [
            -104.57437010370325,
            19.23352915012199
        ],
        "email": "VITRO87@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "655680183cadfb091b0c93ed"
        },
        "address": "Ignacio López Rayón No.298, San Isidro, Comalcalco, Tab.",
        "name": "Vitrocar Comalcalco",
        "state": "Tabasco",
        "phone": "9333340822",
        "phone2": "9371636678",
        "lat": "18.254666811256016",
        "lng": -93.2283267576719,
        "sitio": "https://goo.gl/maps/aU7CoBocrxQLuhn9A",
        "mx": "VITRO88",
        "client": "vitrocar",
        "cotiza": "https://goo.gl/maps/aU7CoBocrxQLuhn9A",
        "deleted_at": null,
        "coordinates": [
            -93.2283267576719,
            18.254666811256016
        ],
        "email": "VITRO88@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "655680183cadfb091b0c93ef"
        },
        "address": "P.º del Mineral 226, Del Fresno, Fraccionamiento del Fresno, Fresnillo, Zac.",
        "name": "Vitrocar Fresnillo",
        "state": "Zacatecas",
        "phone": "4939313473",
        "phone2": "4939334233",
        "lat": "23.18851542847311",
        "lng": -102.87529231349215,
        "sitio": "https://goo.gl/maps/JqJPSwcazUNpe9v78",
        "mx": "VITRO90",
        "client": "vitrocar",
        "cotiza": "https://goo.gl/maps/JqJPSwcazUNpe9v78",
        "deleted_at": null,
        "coordinates": [
            -102.87529231349215,
            23.18851542847311
        ],
        "email": "VITRO90@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "655680183cadfb091b0c93f0"
        },
        "address": "Av. Circunvalación Agustín Yáñez 1456, Moderna, Guadalajara, Jal.",
        "name": "Vitrocar Guadalajara",
        "state": "Jalisco",
        "phone": "3338110414",
        "phone2": "",
        "lat": "20.6614663143941",
        "lng": -103.36048860000001,
        "sitio": "https://goo.gl/maps/qkecPLKauSN935v9A",
        "mx": "VITRO91",
        "client": "vitrocar",
        "cotiza": "https://goo.gl/maps/qkecPLKauSN935v9A",
        "deleted_at": null,
        "coordinates": [
            -103.36048860000001,
            20.6614663143941
        ],
        "email": "VITRO91@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "655680183cadfb091b0c93f1"
        },
        "address": "Carr. Guanajuato - Juventino Rosas Col-KM.6.5, Arroyo Verde, Guanajuato, Gto.",
        "name": "Vitrocar Guanajuato",
        "state": "Guanajuato",
        "phone": "4734834267",
        "phone2": "",
        "lat": "20.98404780562715",
        "lng": -101.28459773371289,
        "sitio": "https://maps.app.goo.gl/fhZXeMcrj6NRKbiW6",
        "mx": "VITRO92",
        "client": "vitrocar",
        "cotiza": "https://maps.app.goo.gl/fhZXeMcrj6NRKbiW6",
        "deleted_at": null,
        "coordinates": [
            -101.28459773371289,
            20.98404780562715
        ],
        "email": "VITRO92@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "655680183cadfb091b0c93f2"
        },
        "address": "Carr. Internacional 206, Antonio de León, 69006 Huajuapan de León, Oax.",
        "name": "Vitrocar Huajuapan",
        "state": "Oaxaca",
        "phone": "9532772739",
        "phone2": "9536883164",
        "lat": "17.816825542801833",
        "lng": -97.79029988650785,
        "sitio": "https://goo.gl/maps/QXpqYCZmn7s9vfm16",
        "mx": "VITRO93",
        "client": "vitrocar",
        "cotiza": "https://goo.gl/maps/QXpqYCZmn7s9vfm16",
        "deleted_at": null,
        "coordinates": [
            -97.79029988650785,
            17.816825542801833
        ],
        "email": "VITRO93@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "655680183cadfb091b0c93f3"
        },
        "address": "16 de Septiembre 579, Barrio de San Vicente, Irapuato, Gto.",
        "name": "Vitrocar Irapuato",
        "state": "Guanajuato",
        "phone": "4626262868",
        "phone2": "4626276159",
        "lat": "20.671352904983923",
        "lng": -101.34052044417972,
        "sitio": "https://goo.gl/maps/HzVNibKRFez9ty7Q6",
        "mx": "VITRO94",
        "client": "vitrocar",
        "cotiza": "https://goo.gl/maps/HzVNibKRFez9ty7Q6",
        "deleted_at": null,
        "coordinates": [
            -101.34052044417972,
            20.671352904983923
        ],
        "email": "VITRO94@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "655680183cadfb091b0c93f4"
        },
        "address": "Av. de Las Américas 1298, La Playa, Cd Juárez, Chih.",
        "name": "Vitrocar Juárez",
        "state": "Chihuahua",
        "phone": "6566161766",
        "phone2": "6566165092",
        "lat": "31.74861656869484",
        "lng": -106.45569598650783,
        "sitio": "https://goo.gl/maps/w4mxmJcXXsetfcYA7",
        "mx": "VITRO95",
        "client": "vitrocar",
        "cotiza": "https://goo.gl/maps/w4mxmJcXXsetfcYA7",
        "deleted_at": null,
        "coordinates": [
            -106.45569598650783,
            31.74861656869484
        ],
        "email": "VITRO95@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "655680183cadfb091b0c93f5"
        },
        "address": "Prosperidad No. 30, Col. Libramiento, El Colomo, Col.",
        "name": "Vitrocar Manzanillo",
        "state": "Colima",
        "phone": "3141636643",
        "phone2": "3143364292",
        "lat": "19.0768916717209",
        "lng": -104.2852705576719,
        "sitio": "https://g.co/kgs/zTHKze",
        "mx": "VITRO96",
        "client": "vitrocar",
        "cotiza": "https://g.co/kgs/zTHKze",
        "deleted_at": null,
        "coordinates": [
            -104.2852705576719,
            19.0768916717209
        ],
        "email": "VITRO96@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "655680183cadfb091b0c93f6"
        },
        "address": "Libramiento No. 207, Col. Enrique Rodríguez Cano, Martínez de la Torre, Ver.",
        "name": "Vitrocar Martínez de la Torre",
        "state": "Veracruz",
        "phone": "2323175903",
        "phone2": "",
        "lat": "20.072255847738667",
        "lng": -97.06688163374187,
        "sitio": "https://maps.app.goo.gl/yFZRbPbY2h7FpH5h7",
        "mx": "VITRO97",
        "client": "vitrocar",
        "cotiza": "https://maps.app.goo.gl/yFZRbPbY2h7FpH5h7",
        "deleted_at": null,
        "coordinates": [
            -97.06688163374187,
            20.072255847738667
        ],
        "email": "VITRO97@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "655680183cadfb091b0c93f7"
        },
        "address": "Blvrd Benito Juárez 308, El Pueblo, Monclova, Coah.",
        "name": "Vitrocar Monclova",
        "state": "Coahuila",
        "phone": "8666332404",
        "phone2": "8666324151",
        "lat": "26.91390673452837",
        "lng": -101.42286322883592,
        "sitio": "https://goo.gl/maps/DH6QBFm15texm6za6",
        "mx": "VITRO98",
        "client": "vitrocar",
        "cotiza": "https://goo.gl/maps/DH6QBFm15texm6za6",
        "deleted_at": null,
        "coordinates": [
            -101.42286322883592,
            26.91390673452837
        ],
        "email": "VITRO98@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "655680183cadfb091b0c93f8"
        },
        "address": "Prolongación Pasteur Sur No. 410, Col. Valle Alameda, Santiago de Querétaro, Qro.",
        "name": "Vitrocar Pasteur",
        "state": "Querétaro",
        "phone": "4422140196",
        "phone2": "4422120122",
        "lat": "20.58225496811008",
        "lng": -100.38406557116404,
        "sitio": "https://goo.gl/maps/58WCkqjeWCmfCm466",
        "mx": "VITRO99",
        "client": "vitrocar",
        "cotiza": "https://goo.gl/maps/58WCkqjeWCmfCm466",
        "deleted_at": null,
        "coordinates": [
            -100.38406557116404,
            20.58225496811008
        ],
        "email": "VITRO99@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "655680a93cadfb091b0c93f9"
        },
        "address": "Calle Pociano Rojos, Las Palmeras, Pichucalco, Chis.",
        "name": "Vitrocar Pichucalco",
        "state": "Chiapas",
        "phone": "9323230032",
        "phone2": "9171260870",
        "lat": "17.514318823871108",
        "lng": -93.11808027116405,
        "sitio": "https://goo.gl/maps/bAwYA5jwQh1Vh9fK7",
        "mx": "VITRO100",
        "client": "vitrocar",
        "cotiza": "https://goo.gl/maps/bAwYA5jwQh1Vh9fK7",
        "deleted_at": null,
        "coordinates": [
            -93.11808027116405,
            17.514318823871108
        ],
        "email": "VITRO100@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "655680a93cadfb091b0c93fa"
        },
        "address": "Av.Buena Vista 310 A, San Felipe, Piedras Negras, Coah.",
        "name": "Vitrocar Piedras Negras",
        "state": "Coahuila",
        "phone": "8787830363",
        "phone2": "8781083494",
        "lat": "28.681362235990782",
        "lng": -100.54357768650783,
        "sitio": "https://goo.gl/maps/yM8oTLmLoeH5SdHz7",
        "mx": "VITRO101",
        "client": "vitrocar",
        "cotiza": "https://goo.gl/maps/yM8oTLmLoeH5SdHz7",
        "deleted_at": null,
        "coordinates": [
            -100.54357768650783,
            28.681362235990782
        ],
        "email": "VITRO101@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "655680a93cadfb091b0c93fb"
        },
        "address": "Arco Vial Av. 115 Entre Constituyentes y Juárez mz 10 lte 1 ",
        "name": "Vitrocar Playa del Carmen",
        "state": "Quintana Roo",
        "phone": "9842673068",
        "phone2": "9841550318",
        "lat": "20.640358184768733",
        "lng": -87.0925249,
        "sitio": "https://goo.gl/maps/MydNgEN6gJkQeGhr7",
        "mx": "VITRO102",
        "client": "vitrocar",
        "cotiza": "https://goo.gl/maps/MydNgEN6gJkQeGhr7",
        "deleted_at": null,
        "coordinates": [
            -87.0925249,
            20.640358184768733
        ],
        "email": "VITRO102@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "655680a93cadfb091b0c93fc"
        },
        "address": "Blvd. Sonora No.225, Centro, Puerto Peñasco, Son.",
        "name": "Vitrocar Puerto Peñasco",
        "state": "Sonora",
        "phone": "6383837171",
        "phone2": "6381130218",
        "lat": "31.315731343248416",
        "lng": -113.5297348423281,
        "sitio": "https://goo.gl/maps/VbKLXfADouj8e3vp9",
        "mx": "VITRO103",
        "client": "vitrocar",
        "cotiza": "https://goo.gl/maps/VbKLXfADouj8e3vp9",
        "deleted_at": null,
        "coordinates": [
            -113.5297348423281,
            31.315731343248416
        ],
        "email": "VITRO103@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "655680a93cadfb091b0c93fd"
        },
        "address": "Av. Luis Vega Monrroy 324, zona dos extendida, Balaustradas,Santiago de Querétaro, Qro.",
        "name": "Vitrocar Querétaro-Balaustradas",
        "state": "Querétaro",
        "phone": "4422237821",
        "phone2": "4422484304",
        "lat": "20.576569309505665",
        "lng": -100.37979691349214,
        "sitio": "https://maps.app.goo.gl/1JLneUQCN76jTBrn7",
        "mx": "VITRO104",
        "client": "vitrocar",
        "cotiza": "https://maps.app.goo.gl/1JLneUQCN76jTBrn7",
        "deleted_at": null,
        "coordinates": [
            -100.37979691349214,
            20.576569309505665
        ],
        "email": "VITRO104@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "655680a93cadfb091b0c93fe"
        },
        "address": "C. 13 de Septiembre 8, Las Campanas,Santiago de Querétaro, Qro.",
        "name": "Vitrocar Querétaro-Campanas",
        "state": "Querétaro",
        "phone": "4422154254",
        "phone2": "4421906885",
        "lat": "20.589582265754753",
        "lng": -100.41259392698431,
        "sitio": "https://goo.gl/maps/k93iVrdxxhLsaL366",
        "mx": "VITRO105",
        "client": "vitrocar",
        "cotiza": "https://goo.gl/maps/k93iVrdxxhLsaL366",
        "deleted_at": null,
        "coordinates": [
            -100.41259392698431,
            20.589582265754753
        ],
        "email": "VITRO105@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "655680a93cadfb091b0c93ff"
        },
        "address": "Carretera Reforma Juárez, Col. Petrolerera, Reforma",
        "name": "Vitrocar Reforma",
        "state": "Chiapas",
        "phone": "9171261436",
        "phone2": "",
        "lat": "17.8490918237643",
        "lng": -93.16328017301566,
        "sitio": "https://goo.gl/maps/jQQaKkTW1SPLejuS6",
        "mx": "VITRO106",
        "client": "vitrocar",
        "cotiza": "https://goo.gl/maps/jQQaKkTW1SPLejuS6",
        "deleted_at": null,
        "coordinates": [
            -93.16328017301566,
            17.8490918237643
        ],
        "email": "VITRO106@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "655680a93cadfb091b0c9400"
        },
        "address": "Blvd. Carlos Jonguitud Barrios 519, Zona Centro, Rioverde, S.L.P.",
        "name": "Vitrocar Rio verde",
        "state": "San Luis Potosí",
        "phone": "4878723554",
        "phone2": "4871108721",
        "lat": "21.923595077040858",
        "lng": -99.99113469999999,
        "sitio": "https://goo.gl/maps/tKuUfbnHeMDHx9mh8",
        "mx": "VITRO107",
        "client": "vitrocar",
        "cotiza": "https://goo.gl/maps/tKuUfbnHeMDHx9mh8",
        "deleted_at": null,
        "coordinates": [
            -99.99113469999999,
            21.923595077040858
        ],
        "email": "VITRO107@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "655680a93cadfb091b0c9401"
        },
        "address": "México 57 Km 114, Gobernadores, Sabinas, Coah.",
        "name": "Vitrocar Sabinas",
        "state": "Coahuila",
        "phone": "8616121355",
        "phone2": "8666324151",
        "lat": "27.86485011238489",
        "lng": -101.13589894232811,
        "sitio": "https://goo.gl/maps/ttcgHvzbUW853JcJ8",
        "mx": "VITRO108",
        "client": "vitrocar",
        "cotiza": "https://goo.gl/maps/ttcgHvzbUW853JcJ8",
        "deleted_at": null,
        "coordinates": [
            -101.13589894232811,
            27.86485011238489
        ],
        "email": "VITRO108@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "655680a93cadfb091b0c9402"
        },
        "address": "Carlos A. Madrazo 102, Independencia, Macuspana, Tab.",
        "name": "Vitrocar San Carlos Macuspana",
        "state": "Tabasco",
        "phone": "9363621234",
        "phone2": "",
        "lat": "17.750769361202227",
        "lng": -92.60629708650785,
        "sitio": "https://goo.gl/maps/EzkTERyCUEShLZHp7",
        "mx": "VITRO109",
        "client": "vitrocar",
        "cotiza": "https://goo.gl/maps/EzkTERyCUEShLZHp7",
        "deleted_at": null,
        "coordinates": [
            -92.60629708650785,
            17.750769361202227
        ],
        "email": "VITRO109@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "655680a93cadfb091b0c9403"
        },
        "address": "Blvd. San José Torres Landa, No. 74, Col. San José de Iturbide, Gto",
        "name": "Vitrocar San José Iturbide",
        "state": "Guanajuato",
        "phone": "4621571116",
        "phone2": "4621571116",
        "lat": "21.030459242637466",
        "lng": -100.38868693555631,
        "sitio": "https://goo.gl/maps/QD4LHLhh5mqdZByTA",
        "mx": "VITRO110",
        "client": "vitrocar",
        "cotiza": "https://goo.gl/maps/QD4LHLhh5mqdZByTA",
        "deleted_at": null,
        "coordinates": [
            -100.38868693555631,
            21.030459242637466
        ],
        "email": "VITRO110@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "655680a93cadfb091b0c9404"
        },
        "address": "Cam. a Sta. Cruz 234, La Loma, San Juan del río, Son.",
        "name": "Vitrocar San Juan del Río",
        "state": "Querétaro",
        "phone": "4272729754",
        "phone2": "",
        "lat": "21.321432250728048",
        "lng": -100.27543308793798,
        "sitio": "https://maps.app.goo.gl/sze8xQeNdYzjYYnj8",
        "mx": "VITRO111",
        "client": "vitrocar",
        "cotiza": "https://maps.app.goo.gl/sze8xQeNdYzjYYnj8",
        "deleted_at": null,
        "coordinates": [
            -100.27543308793798,
            21.321432250728048
        ],
        "email": "VITRO111@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "655680a93cadfb091b0c9405"
        },
        "address": "Av. Zaragoza 1609, Residencias, 83448 San Luis Río Colorado, Son.",
        "name": "Vitrocar San Luis Río Colorado",
        "state": "Sonora",
        "phone": "6535356081",
        "phone2": "6535302232",
        "lat": "32.47198627725433",
        "lng": -114.76393382883595,
        "sitio": "https://goo.gl/maps/ZhMkuAhV4MXSg1qF8",
        "mx": "VITRO112",
        "client": "vitrocar",
        "cotiza": "https://goo.gl/maps/ZhMkuAhV4MXSg1qF8",
        "deleted_at": null,
        "coordinates": [
            -114.76393382883595,
            32.47198627725433
        ],
        "email": "VITRO112@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "655680a93cadfb091b0c9406"
        },
        "address": "Carr. Federal México Puebla km 95, Col. Los Ángeles, San Martín",
        "name": "Vitrocar San Martín",
        "state": "Puebla",
        "phone": "2484847707",
        "phone2": "",
        "lat": "19.277110337363794",
        "lng": -98.43150773068757,
        "sitio": "https://goo.gl/maps/kuZ2KGAkiZHK96Gz8",
        "mx": "VITRO113",
        "client": "vitrocar",
        "cotiza": "https://goo.gl/maps/kuZ2KGAkiZHK96Gz8",
        "deleted_at": null,
        "coordinates": [
            -98.43150773068757,
            19.277110337363794
        ],
        "email": "VITRO113@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "655680a93cadfb091b0c9407"
        },
        "address": "Álvaro Obregón 550, Centro, 28140 Tecomán, Col.",
        "name": "Vitrocar Tecomán",
        "state": "Colima",
        "phone": "3133253415",
        "phone2": "3131203859",
        "lat": "18.90775924853535",
        "lng": -103.86957265582026,
        "sitio": "https://goo.gl/maps/dgScAEpKyTEmogiz6",
        "mx": "VITRO114",
        "client": "vitrocar",
        "cotiza": "https://goo.gl/maps/dgScAEpKyTEmogiz6",
        "deleted_at": null,
        "coordinates": [
            -103.86957265582026,
            18.90775924853535
        ],
        "email": "VITRO114@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "655680a93cadfb091b0c9408"
        },
        "address": "Cedro 4 A Col, Versalles, 63138 Tepic, Nay.",
        "name": "Vitrocar Tepic",
        "state": "Nayarit",
        "phone": "3112102432",
        "phone2": "3112133166",
        "lat": "21.50561483960042",
        "lng": -104.90728887116406,
        "sitio": "https://goo.gl/maps/RqBdGz2p8QmTnd9B6",
        "mx": "VITRO115",
        "client": "vitrocar",
        "cotiza": "https://goo.gl/maps/RqBdGz2p8QmTnd9B6",
        "deleted_at": null,
        "coordinates": [
            -104.90728887116406,
            21.50561483960042
        ],
        "email": "VITRO115@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "655680a93cadfb091b0c9409"
        },
        "address": "Av. Tonalá 1340, Santa Cruz de Las Huertas, Tonalá, Jal.",
        "name": "Vitrocar Tonalá",
        "state": "Jalisco",
        "phone": "3311361403",
        "phone2": "3311361403",
        "lat": "20.617109734160575",
        "lng": -103.27649219999999,
        "sitio": "https://goo.gl/maps/pxdJxqt494Z5Bazt6",
        "mx": "VITRO116",
        "client": "vitrocar",
        "cotiza": "https://goo.gl/maps/pxdJxqt494Z5Bazt6",
        "deleted_at": null,
        "coordinates": [
            -103.27649219999999,
            20.617109734160575
        ],
        "email": "VITRO116@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "655680a93cadfb091b0c940a"
        },
        "address": "Calle F. Berriozabal 534, Ascensión Gómez Mancilla, Cd Victoria, Tamps.",
        "name": "Vitrocar Victoria Matriz",
        "state": "Tamaulipas",
        "phone": "8343147182",
        "phone2": "8343147183",
        "lat": "23.74231287873967",
        "lng": -99.15537555582027,
        "sitio": "https://goo.gl/maps/zU66g3PGV2fcWPSU6",
        "mx": "VITRO117",
        "client": "vitrocar",
        "cotiza": "https://goo.gl/maps/zU66g3PGV2fcWPSU6",
        "deleted_at": null,
        "coordinates": [
            -99.15537555582027,
            23.74231287873967
        ],
        "email": "VITRO117@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "655680a93cadfb091b0c940b"
        },
        "address": "Cadete Juan Escutia 104, Héroes de Chapultepec, Zacatecas, Zac.",
        "name": "Vitrocar Zacatecas",
        "state": "Zacatecas",
        "phone": "4932947213",
        "phone2": "4929247213",
        "lat": "22.77379621069505",
        "lng": -102.6015429846562,
        "sitio": "https://goo.gl/maps/wkQjz9xfpcidobma9",
        "mx": "VITRO118",
        "client": "vitrocar",
        "cotiza": "https://goo.gl/maps/wkQjz9xfpcidobma9",
        "deleted_at": null,
        "coordinates": [
            -102.6015429846562,
            22.77379621069505
        ],
        "email": "VITRO118@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "655680a93cadfb091b0c940c"
        },
        "address": "Martínez de Navarrete 16, La Medallita, Centro, Zamora de Hidalgo, Mich.",
        "name": "Vitrocar Zamora",
        "state": "Michoacán",
        "phone": "3515158612",
        "phone2": "3515156493",
        "lat": "19.980634027203035",
        "lng": -102.29026669999999,
        "sitio": "https://goo.gl/maps/LcD9seNpYCQzvAhv9",
        "mx": "VITRO119",
        "client": "vitrocar",
        "cotiza": "https://goo.gl/maps/LcD9seNpYCQzvAhv9",
        "deleted_at": null,
        "coordinates": [
            -102.29026669999999,
            19.980634027203035
        ],
        "email": "VITRO119@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "655680d33cadfb091b0c940d"
        },
        "address": "Servicio Móvil",
        "name": "Vitrocar Camargo",
        "state": "Chihuahua",
        "phone": "6394740992",
        "phone2": "6394741068",
        "lat": "",
        "lng": null,
        "sitio": "",
        "mx": "VITRO120",
        "client": "vitrocar",
        "cotiza": "",
        "deleted_at": null,
        "coordinates": [
            null,
            null
        ],
        "email": "VITRO120@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "655681e53cadfb091b0c942b"
        },
        "address": "Servicio Móvil",
        "name": "Vitrocar Guadalupe",
        "state": "Nuevo León",
        "phone": "8001118487",
        "phone2": "",
        "lat": "",
        "lng": null,
        "sitio": "http://VITRO121",
        "mx": "VITRO121",
        "client": "vitrocar",
        "cotiza": "http://VITRO121",
        "deleted_at": null,
        "coordinates": [
            null,
            null
        ],
        "email": "VITRO121@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "655681e53cadfb091b0c942c"
        },
        "address": "Servicio Móvil",
        "name": "Vitrocar Jiménez",
        "state": "Chihuahua",
        "phone": "6394790992",
        "phone2": "6394741068",
        "lat": "",
        "lng": null,
        "sitio": "http://VITRO122",
        "mx": "VITRO122",
        "client": "vitrocar",
        "cotiza": "http://VITRO122",
        "deleted_at": null,
        "coordinates": [
            null,
            null
        ],
        "email": "VITRO122@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "655681e53cadfb091b0c942d"
        },
        "address": "Servicio Móvil",
        "name": "Vitrocar Madero",
        "state": "Tamaulipas",
        "phone": "8332276343",
        "phone2": "8332276357",
        "lat": "",
        "lng": null,
        "sitio": "http://VITRO123",
        "mx": "VITRO123",
        "client": "vitrocar",
        "cotiza": "http://VITRO123",
        "deleted_at": null,
        "coordinates": [
            null,
            null
        ],
        "email": "VITRO123@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "655681e53cadfb091b0c942e"
        },
        "address": "Servicio Móvil",
        "name": "Vitrocar Rosarito",
        "state": "Baja California",
        "phone": "6611040414",
        "phone2": "456643747316",
        "lat": "",
        "lng": null,
        "sitio": "http://VITRO124",
        "mx": "VITRO124",
        "client": "vitrocar",
        "cotiza": "http://VITRO124",
        "deleted_at": null,
        "coordinates": [
            null,
            null
        ],
        "email": "VITRO124@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "655681e53cadfb091b0c942f"
        },
        "address": "Servicio Móvil",
        "name": "Vitrocar Sahuayo",
        "state": "Michoacán",
        "phone": "3515158612",
        "phone2": "3515156493",
        "lat": "",
        "lng": null,
        "sitio": "http://VITRO125",
        "mx": "VITRO125",
        "client": "vitrocar",
        "cotiza": "http://VITRO125",
        "deleted_at": null,
        "coordinates": [
            null,
            null
        ],
        "email": "VITRO125@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "655681e53cadfb091b0c9430"
        },
        "address": "Servicio Móvil",
        "name": "Vitrocar Salamanca",
        "state": "Guanajuato",
        "phone": "",
        "phone2": "",
        "lat": "",
        "lng": null,
        "sitio": "http://VITRO126",
        "mx": "VITRO126",
        "client": "vitrocar",
        "cotiza": "http://VITRO126",
        "deleted_at": null,
        "coordinates": [
            null,
            null
        ],
        "email": "VITRO126@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "655681e53cadfb091b0c9431"
        },
        "address": "Servicio Móvil",
        "name": "Vitrocar San Pedro",
        "state": "Nuevo León",
        "phone": "8001118487",
        "phone2": "",
        "lat": "",
        "lng": null,
        "sitio": "http://VITRO127",
        "mx": "VITRO127",
        "client": "vitrocar",
        "cotiza": "http://VITRO127",
        "deleted_at": null,
        "coordinates": [
            null,
            null
        ],
        "email": "VITRO127@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "655681e53cadfb091b0c9432"
        },
        "address": "Servicio Móvil",
        "name": "Vitrocar Santa Catarina",
        "state": "Nuevo León",
        "phone": "8001118487",
        "phone2": "",
        "lat": "",
        "lng": null,
        "sitio": "http://VITRO128",
        "mx": "VITRO128",
        "client": "vitrocar",
        "cotiza": "http://VITRO128",
        "deleted_at": null,
        "coordinates": [
            null,
            null
        ],
        "email": "VITRO128@vitro.com.mx"
    },
    {
        "_id": {
            "$oid": "655681e53cadfb091b0c9433"
        },
        "address": "Servicio Móvil",
        "name": "Vitrocar Uruapan",
        "state": "Michoacan",
        "phone": "3515158612",
        "phone2": "3515156493",
        "lat": "",
        "lng": null,
        "sitio": "http://VITRO129",
        "mx": "VITRO129",
        "client": "vitrocar",
        "cotiza": "http://VITRO129",
        "deleted_at": null,
        "coordinates": [
            null,
            null
        ],
        "email": "VITRO129@vitro.com.mx"
    }
]