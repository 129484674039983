
import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { makeGET } from "../../helpers/makeRequest";
import 'react-phone-number-input/style.css'
import { MDBDataTableV5 } from 'mdbreact';
import TextField from '@mui/material/TextField'
import moment from 'moment';
import 'moment/locale/es';
export const LogScreen = () => {
  const user = useSelector((state) => state.auth);
  useEffect(() => {
    getDatos();

  }, []);
  const [datos, setDatos] = useState([]);
  const [search, setSearch] = useState("")
  const [selectedClient, setSelectedClient] = useState((localStorage.getItem("selectedClient")))
  const getDatos = async () => {
    makeGET("log/get/" + selectedClient).then(({ data }) => {
      data = [...data.sort((a, b) => new Date(b.created) - new Date(a.created))]
      console.log(data)
      data.forEach(element => {
        element.fecha = moment(new Date(element.created)).format('MMM/DD/YYYY HH:mm:ss').toUpperCase().replace('.', '')
      });
      //console.log(data)
      const data_conf = {
        columns: [
          {
            label: 'Nombre',
            field: 'name',
          },
          {
            label: 'Franquicia',
            field: 'franchise',
          },
          {
            label: 'Acción',
            field: 'action',
          },
          {
            label: 'Creado',
            field: 'fecha',
          }
        ],
        rows: data
      }
      setDatos(data_conf)
    }).catch((e) => {

      //console.log(JSON.parse(JSON.stringify(e)).status)
    })
  };

  const searchDatos = async () => {
    makeGET("log/search", { word: search }).then(({ data }) => {
      data.sort((a, b) => new Date(b.created) - new Date(a.created))
      const data_conf = {
        columns: [
          {
            label: 'Nombre',
            field: 'name',
          },
          {
            label: 'Franquicia',
            field: 'franchise',
          },
          {
            label: 'Acción',
            field: 'action',
          },
          {
            label: 'Creado',
            field: 'created',
          }
        ],
        rows: data
      }
      setDatos(data_conf)
    }).catch((e) => {

      //console.log(JSON.parse(JSON.stringify(e)).status)
    })
  };


  return (
    <>
      <div className="card m-3">
        <h5 className="card-header d-flex justify-content-between align-items-center">
          Log
        </h5>
        <div className="card-body row">
          {/* <div className={"col-12 col-lg-9 text-center mb-3"}>
            <TextField
              type="text"
              fullWidth
              size="small"
              sx={{ input: { backgroundColor: "whitesmoke", borderRadius: '2px' } }}
              onChange={(e) => {
                setSearch(e.target.value)
              }}
              value={search}
              label="Buscar..."
              autoComplete="off"
              variant="outlined"
            />
          </div>
          <div className="col-12 col-lg-3 text-center mb-3">
            <button
              type="button"
              className={"btn btn-large btn-primary"}
              onClick={searchDatos}
              disabled={String(search).length == 0}
              style={{
                paddingLeft: "2.5rem",
                paddingRight: "2.5rem",
                fontSize: "18px",
              }}
            >
              Buscar
            </button>
          </div> */}
          <div className="col-12">
            <MDBDataTableV5
              striped
              responsiveSm
              responsive
              responsiveMd
              responsiveLg
              responsiveXl
              hover
              entriesLabel="Por página"
              pagingTop
              infoLabel={["Página", " - ", "de", "registros"]}
              paginationLabel={["Anterior", "Siguiente"]}
              searchLabel="Buscar..."
              className="mdb"
              searchTop
              searching={true}
              searchBottom={false}
              noBottomColumns
              data={datos}
            />
          </div>
        </div>
      </div>
    </>
  );
};
