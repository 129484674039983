
import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { makeDELETE, makeGET, makePATCH, makePOST } from "../../helpers/makeRequest";
import { conf } from '../../config/config'
import 'react-phone-number-input/style.css'
import Swal from "sweetalert2";
import TextField from '@mui/material/TextField'
import InputMask from 'react-input-mask'
import MenuItem from '@mui/material/MenuItem';
import { MDBDataTableV5 } from 'mdbreact';
import { makeStyles } from "@material-ui/core/styles";
import { sucursales } from "./j";

export const FranquiciaScreen = () => {
  const user = useSelector((state) => state.auth);
  useEffect(() => {
    getDatos();
    /*data = data.map((v, i) => {
      return {
        ...v, client: "phar", mx: "PHAR" + i, email: "phar" + i + "@phar.com.mx",
        sitio: v.sitio == "" ? "https://maps.google.com/?q=" + v.lat + "," + v.lng : v.sitio,
        deleted_at: null, coordinates: [parseFloat(v.lat), parseFloat(v.lng)],
        cotiza: i, phone: String(v.phone).replace(/\D/g, ""), phone2: String(v.phone2).replace(/\D/g, ""),
      }
    })
    data.slice(2).map(v=>{
      makePOST("franchise/create", v).then((v) => {
        console.log("agregado")
      }).catch((c) => {
        console.log(c)
      })
    })
    console.log(data.slice(2))
    makePOST("franchise/create", data[0]).then((v) => {
      console.log("agregado")
    }).catch((c) => {
      console.log(c)
    })*/
  }, []);
  const [datos, setDatos] = useState([]);

  const [adding, setAdding] = useState(false);

  const [form, setForm] = useState({
    mx: undefined,
    name: undefined,
    state: undefined,
    phone: undefined,
    phone2: undefined,
    address: undefined,
    email: undefined,
    lat: undefined,
    lng: undefined,
    coordinates: [undefined, undefined],
    sitio: undefined,
    cotiza: undefined
  })

  const isLatitude = num => isFinite(num) && Math.abs(num) <= 90 && String(num).length != 0;
  const isLongitude = num => isFinite(num) && Math.abs(num) <= 180 && String(num).length != 0;

  const [flag, setFlag] = useState(false)

  const handleEdit = (obj) => {
    setForm({ ...obj, phone: String(obj.phone).replace(/\D/g, ""), phone2: String(obj.phone2).replace(/\D/g, "") })
    setFlag(true)
    setAdding(true)
  };

  const getDatos = async () => {

    makeGET("franchise/all/" + localStorage.getItem("selectedClient")).then(({ data }) => {
      //console.log("datos", data)
      data.forEach(element => {
        element.buttons =
          <div className="text-center">
            <button key="update" onClick={e => { handleEdit(element) }} className="btn btn-sm btn-primary mr-1">
              <i className="fas fa-edit"></i>
            </button>
            <button key="delete" onClick={e => { deleteDatos(element) }} className="btn btn-sm btn-danger">
              <i className="fas fa-trash"></i>
            </button>
          </div>
      });
      const data_conf = {
        columns: [
          {
            label: 'MX',
            field: 'mx'
          },
          {
            label: 'Name',
            field: 'name',
          },
          {
            label: 'State',
            field: 'state',
          },
          {
            label: 'Sitio',
            field: 'sitio',
          },
          {
            label: '',
            field: 'buttons'
          }
        ],
        rows: data
      }

      /*data1 = data1.map((v, i) => {
        return {
          ...v, client: "phar", mx: "PHAR" + i, email: "phar" + i + "@phar.com.mx",
          sitio: v.sitio == "" ? "https://maps.google.com/?q=" + v.lat + "," + v.lng : v.sitio,
          deleted_at: null, coordinates: [parseFloat(v.lat), parseFloat(v.lng)],
          cotiza: i, phone: String(v.phone).replace(/\D/g, ""), phone2: String(v.phone2).replace(/\D/g, ""),
        }
      })

      let difference = [...new Set(data1.map(v => v.mx))].filter(x => ![...new Set(data.map(v => v.mx))].includes(x)).map(v => {
        return {...data1.find(d => d.mx == v), name: data1.find(d => d.mx == v).name + " 2"}
      })*/
      console.log(data)
      /*difference.map(v => {
        makePOST("franchise/create", v).then((v) => {
          console.log("agregado")
        }).catch((c) => {
          console.log(c)
        })
      })*/
      setDatos(data_conf)
    }).catch((e) => {
      //console.log(JSON.stringify(e))
    })
  };
  const createPayload = (forms, clean = false) => {
    let payload = {
      ...forms,
      phone: (forms.phone || forms.phone != "") ? String(forms.phone).replace(/\s/g, '') : "0",
      phone2: (forms.phone2 || forms.phone2 != "") ? String(forms.phone2).replace(/\s/g, '') : "0",
      sitio: (forms.sitio != undefined && forms.sitio != "") ? forms.sitio : "",
      cotiza: (forms.cotiza != undefined && forms.cotiza != "") ? forms.cotiza : "",
      lng: parseFloat(form.lng),
      deleted_at: null,
      client: localStorage.getItem("selectedClient"),
      coordinates: [parseFloat(forms.lng), parseFloat(forms.lat)],
    }
    if (clean) {
      payload.id = forms._id.$oid
      delete payload.buttons
      delete payload._id
      delete payload.client
    }
    return payload
  }
  const createDatos = async () => {
    const payload = createPayload(form)
    makePOST("franchise/create", payload).then((v) => {
      getDatos()
      resetForm()
      setFlag(false)
      setAdding(false)
      Swal.fire("Éxito!", "Registro agregado", "success")
    }).catch((c) => {
      Swal.fire("Error", "Ocurrió un error", "error")
    })
  }

  const resetForm = () => {
    Object.keys(form).map((key) => {
      form[key] = undefined
      if (key == "coordinates") {
        form[key] = [undefined, undefined]
      }
    })
  }
  useEffect(() => {
    //console.log(localStorage.getItem("selectedClient"))
  }, []);
  const updateDatos = async (val) => {
    const payload = createPayload(form, true)

    makePATCH("franchise/update", payload).then((v) => {
      getDatos()
      setFlag(false)
      setAdding(false)
      resetForm()
      Swal.fire("Éxito!", "Registro actualizado", "success")
    }).catch((c) => {
      //console.log(c)
      Swal.fire("Error", "Ocurrió un error", "error")
    })
  };

  const deleteDatos = async (val) => {
    Swal.fire("Atención", "¿Estás seguro que quieres eliminar los registros seleccionados? " + val.name, "warning")
      .then((result) => {
        if (result.isConfirmed) {
          makeDELETE("franchise/delete", { id: val._id.$oid }).then((v) => {
            getDatos()
            Swal.fire("Éxito!", "Borrado completado", "success")
          }).catch((c) => {
            Swal.fire("Error", "Ocurrió un error", "error")
          })
        }
      })
  };

  const formatPhone = (phoneNumberString) => {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = (match[1] ? '+1 ' : '');
      return [intlCode, match[2], ' ', match[3], ' ', match[4]].join('');
    }
    return phoneNumberString;
  }

  const checkPhone = (phoneNumberString) => {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return true
    }
    return false
  }


  const toggleAdd = () => {
    setFlag(false)
    setAdding(true);
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  const returnNumbers = (str, key) => {
    let aux = { ...form }
    if (/^[+-]?\d*(?:[.,]\d*)?$/.test(str)) {
      aux[key] = str
      setForm({ ...aux })
    }
  }
  const undReturn = (val) => {
    if (val == undefined) {
      return ""
    }
    return val
  }

  const toggleClose = () => {
    setAdding(false);
  };

  const checkForm = () => {
    if (form.mx?.length == 0 || form.name?.length == 0 || form.state?.length == 0 || form.state?.length == 0
      || !validateEmail(form.email) || form.address?.length == 0
      || !(isLatitude(form.lat)) || !(isLongitude(form.lng))) {
      return true
    }
    return false
  }

  return (
    <>
      <div className="card m-3">
        <h5 className="card-header d-flex justify-content-between align-items-center">
          Franquicias
          {adding ? (
            <button
              type="button"
              className="btn btn-sm btn-danger"
              onClick={() => {
                toggleClose()
                if (flag) {
                  setFlag(false)
                  resetForm()
                }
              }}
            >
              <i className="fas fa-times"></i>
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-sm btn-success"
              onClick={() => toggleAdd()}
            >
              <i className="fas fa-plus"></i>
            </button>
          )}
        </h5>
        {adding && (
          <div className="card-body row">
            <div className={"col-12 col-lg-6 text-center" + (!(form.mx?.length == 0 && form.mx != undefined) && ' mb-3')}>
              <TextField
                type="text"
                fullWidth
                size="small"
                sx={{ input: { backgroundColor: "whitesmoke", borderRadius: '2px' } }}
                onChange={(e) => {
                  setForm({ ...form, mx: e.target.value })
                }}
                value={undReturn(form.mx)}
                label="MX"
                autoComplete="off"
                placeholder="MX248"
                //disabled={flag}
                required
                variant="outlined"
                error={(form.mx?.length == 0 && form.mx != undefined)}
                helperText={(form.mx?.length == 0 && form.mx != undefined) && "El campo MX es obligatorio"}
                style={{ marginBottom: (form.mx?.length == 0 && form.mx != undefined) && '5px' }}
              />
            </div>
            <div className={"col-12 col-lg-6 text-center" + (!(form.name?.length == 0 && form.name != undefined) && ' mb-3')}>
              <TextField
                type="text"
                fullWidth
                onChange={(e) => {
                  setForm({ ...form, name: e.target.value })
                }}
                value={undReturn(form.name)}
                autoComplete="off"
                placeholder={localStorage.getItem("selectedClient") + "..."}
                required
                size="small"
                sx={{ input: { backgroundColor: "whitesmoke", borderRadius: '2px' } }}
                variant="outlined"
                label="Name"
                error={(form.name?.length == 0 && form.name != undefined)}
                helperText={(form.name?.length == 0 && form.name != undefined) && "El campo name es obligatorio"}
                style={{ marginBottom: (form.name?.length == 0 && form.name != undefined) && '5px' }}
              />
            </div>
            <div className={"col-12 col-lg-6 text-center" + (!(form.state?.length == 0 && form.state != undefined) && ' mb-3')}>
              <TextField
                id="outlined-select-currency"
                select
                label="State"
                autoComplete="off"
                placeholder="Estado"
                fullWidth
                sx={{ boxShadow: { backgroundColor: "whitesmoke", borderRadius: '2px' } }}
                value={undReturn(form.state)}
                onChange={(e) => {
                  setForm({ ...form, state: e.target.value })
                }}
                error={(form.state?.length == 0 && form.state != undefined)}
                helperText={(form.state?.length == 0 && form.state != undefined) && "El state es requerido"}
                size="small"
                variant="outlined"
                style={{ marginBottom: (form.state?.length == 0 && form.state != undefined) && '5px' }}
              >
                {conf.states.map((r) => (
                  <MenuItem key={r.name} value={r.name}>
                    {r.name}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className={"col-12 col-lg-6 text-center" + (!(!validateEmail(form.email) && form.email != undefined) && ' mb-3')}>
              <TextField
                type="email"
                fullWidth
                onChange={(e) => {
                  setForm({ ...form, email: e.target.value })
                }}
                value={undReturn(form.email)}
                autoComplete="off"
                placeholder={localStorage.getItem("selectedClient") + "@..."}
                required
                error={(!validateEmail(form.email) && form.email != undefined)}
                helperText={(!validateEmail(form.email) && form.email != undefined) && "Formato de email no válido"}
                size="small"
                variant="outlined"
                label="Email"
                sx={{ input: { backgroundColor: "whitesmoke", borderRadius: '2px' } }}
              //style={{ marginBottom: (!validateEmail(form.email) && form.email != undefined) && '5px' }}
              />
            </div>
            <div className={"col-12 text-center" + (!(form.address?.length == 0 && form.address != undefined) && ' mb-3')}>
              <TextField
                type="text"
                rows="1"
                fullWidth
                onChange={(e) => {
                  setForm({ ...form, address: e.target.value })
                }}
                value={undReturn(form.address)}
                autoComplete="off"
                placeholder="Calle #1 Colonia, Ciudad, Estado, CP"
                required
                error={(form.address?.length == 0 && form.address != undefined)}
                helperText={(form.address?.length == 0 && form.address != undefined) && "El campo address es requerido"}
                size="small"
                variant="outlined"
                sx={{ input: { backgroundColor: "whitesmoke", borderRadius: '2px' } }}
                label="Address"
                style={{ marginBottom: (form.address?.length == 0 && form.address != undefined) && '5px' }}
              />
            </div>
            <div className={"col-12 col-lg-6 text-center mb-3"
              //+ (!(!checkPhone(form.phone) && form.phone != undefined) && ' mb-3')
            }>
              <InputMask
                mask="999 999 9999"
                value={undReturn(form.phone)}
                disabled={false}
                maskChar=" "
                onChange={(e) => {
                  setForm({ ...form, phone: (e.target.value) })
                }}
              >
                {() => <TextField
                  type="text"
                  autoComplete="off"
                  placeholder="555 555 5555"
                  sx={{ input: { backgroundColor: "whitesmoke", borderRadius: '2px' } }}
                  fullWidth
                  //error={(!checkPhone(form.phone) && form.phone != undefined)}
                  //helperText={(!checkPhone(form.phone) && form.phone != undefined) && "Debe tener 10 números"}
                  size="small"
                  variant="outlined"
                  label="Phone"
                //style={{ marginBottom: (!checkPhone(form.phone) && form.phone != undefined) && '5px' }} 
                />}
              </InputMask>
            </div>
            <div className={"col-12 col-lg-6 text-center mb-3"
              //+ (!(!checkPhone(form.phone2) && form.phone2 != undefined) && ' mb-3')
            }>
              <InputMask
                mask="999 999 9999"
                value={undReturn(form.phone2)}
                disabled={false}
                maskChar=" "
                onChange={(e) => {
                  setForm({ ...form, phone2: (e.target.value) })
                }}
              >
                {() => <TextField
                  type="text"
                  autoComplete="off"
                  placeholder="444 444 4444"
                  sx={{ input: { backgroundColor: "whitesmoke", borderRadius: '2px' } }}
                  fullWidth
                  //error={(!checkPhone(form.phone2) && form.phone2 != undefined)}
                  //helperText={(!checkPhone(form.phone2) && form.phone2 != undefined) && "Debe tener 10 números"}
                  size="small"
                  variant="outlined"
                  label="Phone 2"
                //style={{ marginBottom: (!checkPhone(form.phone2) && form.phone2 != undefined) && '5px' }} 
                />}
              </InputMask>
            </div>
            <div className={"col-12 col-lg-6 text-center" + (!(!(isLatitude(form.lat)) && form.lat != undefined) && ' mb-3')}>
              <TextField
                type="text"
                step={0.000001}
                fullWidth
                onChange={(e) => {
                  returnNumbers(String(e.target.value), "lat")
                }}
                value={undReturn(form.lat)}
                autoComplete="off"
                placeholder="19.5..."
                required
                sx={{ input: { backgroundColor: "whitesmoke", borderRadius: '2px' } }}
                error={(!(isLatitude(form.lat)) && form.lat != undefined)}
                helperText={(!(isLatitude(form.lat)) && form.lat != undefined) && "Debe ser numérico entre -90 y 90."}
                size="small"
                variant="outlined"
                label="Lat"
                style={{ marginBottom: (!(isLatitude(form.lat)) && form.lat != undefined) && '5px' }}
              />
            </div>
            <div className={"col-12 col-lg-6 text-center" + (!(!(isLongitude(form.lng)) && form.lng != undefined) && ' mb-3')}>
              <TextField
                type="text"
                step={0.000001}
                fullWidth
                onChange={(e) => {
                  returnNumbers(String(e.target.value), "lng")
                }}
                value={undReturn(form.lng)}
                autoComplete="off"
                placeholder="19.5..."
                required
                sx={{ input: { backgroundColor: "whitesmoke", borderRadius: '2px' } }}
                error={(!(isLongitude(form.lng)) && form.lng != undefined)}
                helperText={(!(isLongitude(form.lng)) && form.lng != undefined) && "Debe ser numérico entre -180 y 180."}
                size="small"
                variant="outlined"
                label="Lng"
                style={{ marginBottom: (!(isLongitude(form.lng)) && form.lng != undefined) && '5px' }}
              />
            </div>
            <div className={"col-12 col-lg-6 text-center mb-3"
              //+ (!(form.sitio?.length == 0 && form.sitio != undefined) && ' mb-3')
            }>
              <TextField
                type="text"
                fullWidth
                onChange={(e) => {
                  setForm({ ...form, sitio: e.target.value })
                }}
                value={undReturn(form.sitio)}
                autoComplete="off"
                placeholder={localStorage.getItem("selectedClient") + ".com"}
                sx={{ input: { backgroundColor: "whitesmoke", borderRadius: '2px' } }}
                //error={(form.sitio?.length == 0 && form.sitio != undefined)}
                //helperText={(form.sitio?.length == 0 && form.sitio != undefined) && "El sitio es requerido"}
                size="small"
                variant="outlined"
                label="Sitio"
                style={{ marginBottom: (form.sitio?.length == 0 && form.sitio != undefined) && '5px' }}
              />
            </div>
            <div className={"col-12 col-lg-6 text-center mb-3"
              //+ (!(form.cotiza?.length == 0 && form.cotiza != undefined) && ' mb-3')
            }>
              <TextField
                type="text"
                fullWidth
                onChange={(e) => {
                  setForm({ ...form, cotiza: e.target.value })
                }}
                value={undReturn(form.cotiza)}
                autoComplete="off"
                placeholder={localStorage.getItem("selectedClient") + ".com/cotiza"}
                sx={{ input: { backgroundColor: "whitesmoke", borderRadius: '2px' } }}
                //error={(form.cotiza?.length == 0 && form.cotiza != undefined)}
                //helperText={(form.cotiza?.length == 0 && form.cotiza != undefined) && "El campo cotiza es requerido"}
                size="small"
                variant="outlined"
                label="Cotiza"
                style={{ marginBottom: (form.cotiza?.length == 0 && form.cotiza != undefined) && '5px' }}
              />
            </div>
            <div className="col-12 text-center mt-2">
              <button
                type="button"
                className={"btn btn-large " + (flag ? "btn-success" : "btn-primary")}
                onClick={flag ? updateDatos : createDatos}
                disabled={checkForm()}
                style={{
                  paddingLeft: "2.5rem",
                  paddingRight: "2.5rem",
                  fontSize: "18px",
                }}
              >
                {flag ? "Modificar" : "Añadir"}
              </button>
            </div>
          </div>
        )}
        <div className="card-body row">
          <div className="col-12">
            <MDBDataTableV5
              striped
              responsiveSm
              responsive
              responsiveMd
              responsiveLg
              responsiveXl
              hover
              entriesLabel="Por página"
              pagingTop
              infoLabel={["Página", " - ", "de", "registros"]}
              paginationLabel={["Anterior", "Siguiente"]}
              searchLabel="Buscar..."
              className="mdb"
              searchTop
              searchBottom={false}
              noBottomColumns
              data={datos}
            />
          </div>
        </div>
      </div>
    </>
  );
};
