import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router';
import { FranquiciaScreen } from './FranquiciaScreen';
import { startLogout } from '../../actions/auth';
import { useSelector, useDispatch } from 'react-redux';
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import { LogScreen } from './LogScreen';
import { UsuarioScreen } from './UsuarioScreen';
import { FrameScreen } from './FrameScreen';
import { InicioScreen } from './InicioScreen';
import logo from '../../imgs/adn_logo_transp.png'
export const PrincipalScreen = () => {
    const { actualPage } = useParams()
    const dispatch = useDispatch();
    const handleLogout = () => {
        dispatch(startLogout())
    }
    const history = useHistory()
    const user = useSelector(state => state.auth);
    const [page, setPage] = useState(actualPage == ':actualPage' ? (userData?.rol == "admin" ? 'franquicia' : 'inicio') : actualPage)

    const handleChangeOfRoute = (route) => {
        setPage(route)
        history.push('/' + route)
    }
    const [userData, setUserData] = useState(JSON.parse(localStorage.getItem("userProps")))
    const [selectedClient, setSelectedClient] = useState((localStorage.getItem("selectedClient")))
    const [notSelectedClients, setNotSelectedClients] = useState([])
    const [reloading, setReloading] = useState(false)
    const changeClient = (val) => {
        setSelectedClient(val)
        localStorage.setItem('selectedClient', val)
        setReloading(true)
        setTimeout(() => {
            setReloading(false)
        }, 10)
    }
    useEffect(() => {
        setNotSelectedClients(userData?.clients?.filter(v => v != selectedClient) || [])
    }, [selectedClient])
    return (
        <>
            <Navbar expand="lg" bg="light" variant="light">
                <Container>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Brand
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            (userData?.rol == "admin") ? handleChangeOfRoute('inicio') : handleChangeOfRoute('franquicia')
                        }}>
                        <img
                            src={logo}
                            width="150"
                            className="d-inline-block"
                            alt="React Bootstrap logo"
                        />
                    </Navbar.Brand>
                    {userData?.rol == "admin" &&
                        <Navbar.Brand onClick={() => handleChangeOfRoute('inicio')}>
                            <span className="text-dark">Inicio</span>
                        </Navbar.Brand>
                    }
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav>
                            <Nav.Link onClick={() => handleChangeOfRoute('franquicia')}>
                                <span className="text-dark">Franquicias <i className="fas fa-briefcase"></i></span>
                            </Nav.Link>
                        </Nav>
                        {userData?.rol == "admin" &&
                            <>
                                <Nav>
                                    <Nav.Link onClick={() => handleChangeOfRoute('log')}>
                                        <span className="text-dark">Log <i className="fas fa-clipboard-list"></i></span>
                                    </Nav.Link>
                                </Nav>
                            </>
                        }
                        <Nav>
                            <Nav.Link onClick={() => handleChangeOfRoute('user')}>
                                <span className="text-dark">{userData?.rol == "admin" ? "Usuarios" : "Usuario"} <i className="fas fa-users"></i></span>
                            </Nav.Link>
                        </Nav>
                        <Nav>
                            <Nav.Link onClick={() => handleChangeOfRoute('frame')}>
                                <span className="text-dark">Mapa <i className="fas fa-map"></i></span>
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                    <Navbar.Collapse className="justify-content-end">
                        <Nav>
                            {notSelectedClients.length != 0 ?
                                <NavDropdown title={
                                    <span className="text-dark">{String(selectedClient).toUpperCase()}</span>
                                } className="text-dark" id="basic-nav-dropdown" style={{ color: 'white !important' }}>
                                    {notSelectedClients.map((val, idx) => (
                                        <NavDropdown.Item key={idx} className="font" onClick={(e) => {
                                            changeClient(val)
                                        }}>
                                            <span className="text-dark">{String(val).toUpperCase()}</span>
                                        </NavDropdown.Item>
                                    ))}
                                </NavDropdown>
                                :
                                <Nav.Link>
                                    <span className="text-dark">{String(selectedClient).toUpperCase()}</span>
                                </Nav.Link>
                            }
                        </Nav>
                        <Nav>
                            <Nav.Link onClick={handleLogout}>
                                <span className="text-dark">Salir </span>
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            {
                !reloading &&
                <>
                    {
                        (page == 'inicio' && userData?.rol == "admin" && true && true) &&
                        <InicioScreen />
                    }
                    {
                        (page == 'franquicia') &&
                        <FranquiciaScreen />
                    }
                    {
                        (page == 'log' && userData?.rol == "admin") &&
                        <LogScreen />
                    }
                    {
                        (page == 'user') &&
                        <UsuarioScreen />
                    }
                    {
                        (page == 'frame') &&
                        <FrameScreen />
                    }
                </>
            }
        </>
    )
}
